import { Invoice, InvoiceItemType } from "../../models";
import { Box, Typography } from "@mui/material";
import React from "react";
import MainCard from "../shared/MainCard";
import { generateColorGradient } from "../../utils/generateColorGradient";
import { formatCurrency } from "../../utils/formatters";

type InvoiceDetailsProps = {
  invoice: Invoice;
};

export const CurrentMonthInvoiceView = ({ invoice }: InvoiceDetailsProps) => {
  const colors = generateColorGradient(5);

  const displayInvoiceItem = (title: string, value: number, color: string) => {
    return (
      // @ts-ignore-next-line
      <MainCard
        content={false}
        sx={{
          width: "100%",
          backgroundColor: color,
          textAlign: "right",
          padding: 2
        }}
      >
        <Typography color="white">{title}</Typography>
        <Typography color="white" variant="h5" py={1}>
          {formatCurrency(value)}
        </Typography>
      </MainCard>
    );
  };

  const employeeSpend = invoice.items.find(x => x.type === InvoiceItemType.EMPLOYEE_SPEND);
  const platformFees = invoice.items
    .filter(x => x.type === InvoiceItemType.MONTHLY_USER_FEE
      || x.type === InvoiceItemType.ISSUED_CARD_FEE
      || x.type === InvoiceItemType.RENEW_CARD_FEE);

  return (
    <>
      <Typography variant="h6">
        For current month, {invoice.period.title}
      </Typography>
      <Box display="flex" gap={2} pt={2}>
        {displayInvoiceItem("Platform Fees", platformFees?.reduce((sum, item) => sum + item.total, 0) ?? 0, colors[0])}
        {displayInvoiceItem("Employee Spend", employeeSpend?.total ?? 0, colors[1])}
        {displayInvoiceItem("Total for Month", invoice.amount.amount, colors[2])}
      </Box>
    </>
  );
};

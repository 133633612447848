import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from "@mui/material";
import { useState } from "react";
import {
  BarChart,
  BarChart2,
  ChevronUp,
  Layers,
  List as ListIcon,
  LogOut,
  Percent,
  Settings,
  Tag,
  User,
  Users
} from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BlueBannerLogo from "../../assets/logos/BlueBanner.png";
import { MembershipType } from "../../models";
import { useData } from "../../utils/useData";

export const DRAWER_WIDTH = 250;

type NavItem = {
  text: string;
  to: string;
  Icon: any;
  subItems?: NavItem[];
  order?: number | undefined;
};

function sortNavItems(navItems: NavItem[]): NavItem[] {
  return navItems.sort((a, b) => {
    const orderA = a.order === undefined ? Number.MAX_SAFE_INTEGER : a.order;
    const orderB = b.order === undefined ? Number.MAX_SAFE_INTEGER : b.order;

    return orderA - orderB;
  });
}

export const Sidebar = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { employer, membership } = useData();
  const { logout } = useKindeAuth();
  const [showSubItems, setShowSubItems] = useState(false);

  const settingsNavItem: NavItem = {
    text: "Settings",
    to: "/settings",
    Icon: Settings,
    subItems: [
      {
        text: "Plans",
        to: "/plans",
        Icon: Layers,
        order: 1,
      },
      {
        text: "Employee Tags",
        to: "/employee-tags",
        Icon: Tag,
        order: 10,
      },

      {
        text: "Account",
        to: "/account",
        Icon: User,
        order: 100,
      }
    ]
  };

  if (!employer.isLoading && employer.data && membership.data?.type !== MembershipType.PILOT && !employer.data!.paymentOptions.isFree) {
    settingsNavItem.subItems?.push({
      text: "Billing",
      to: "/billing",
      Icon: ListIcon,
      order: 20,
    });
    settingsNavItem.subItems?.push({
      text: "Float Management",
      to: "/float-management",
      Icon: Percent,
      order: 30,
    });
  }

  const navigationItems: NavItem[] = [
    {
      text: "Dashboard",
      to: "/",
      Icon: BarChart,
      order: 10,
    },
    {
      text: "Employees",
      to: "/employees",
      Icon: Users,
      order: 20,
    },
    {
      text: "Reporting",
      to: "/reporting",
      Icon: BarChart2,
      order: 30,
    },
    settingsNavItem
  ];

  const NavItem = ({ text, to, Icon, subItems }: NavItem) => {
    const isSelected = to === location.pathname;

    return (
      <Box>
        <ListItem
          key={to}
          disablePadding
          sx={{ textDecoration: "none" }}
          onClick={() => {
            if (subItems) {
              return setShowSubItems(!showSubItems);
            }
            return navigate(to);
          }}
        >
          <ListItemButton
            selected={isSelected}
            sx={{
              borderRight: isSelected ? "2px solid" : "",
              borderColor: "primary.main"
            }}
          >
            <ListItemIcon
              sx={{
                color: isSelected ? "primary.main" : "grey.700",
                width: 34,
                alignItems: "center"
              }}
            >
              <Icon size={16} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  color={isSelected ? "primary.main" : "text.primary"}
                >
                  {text}
                </Typography>
              }
            />
            {subItems && showSubItems && (
              <ChevronUp size={20} color={theme.palette.grey[600]} />
            )}
          </ListItemButton>
        </ListItem>
        {subItems && showSubItems && (
          <List sx={{ paddingLeft: 5 }}>
            {sortNavItems(subItems).map(({ text, to, Icon }) => (
              <ListItem
                key={to}
                disablePadding
                component={Link}
                to={to}
                sx={{
                  textDecoration: "none",
                  borderRight: to === location.pathname ? "2px solid" : "",
                  borderColor: "primary.main"
                }}
              >
                <ListItemButton
                  selected={to === location.pathname}
                  sx={{ paddingLeft: 2 }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        to === location.pathname ? "primary.main" : "grey.700",
                      width: 34,
                      alignItems: "center"
                    }}
                  >
                    <Icon size={16} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        color={
                          to === location.pathname
                            ? "primary.main"
                            : "text.primary"
                        }
                      >
                        {text}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    );
  };

  return (
    <Box component={"nav"}>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
            borderRight: `1px solid ${theme.palette.divider}`,
            backgroundImage: "none",
            boxShadow: "inherit"
          }
        }}
      >
        <List>
          <ListItem
            component={Link}
            to="/"
            sx={{ mb: 2, justifyContent: "center" }}
          >
            <img src={BlueBannerLogo} alt="HeathNow logo" width={165} />
          </ListItem>
          {sortNavItems(navigationItems).map(({ text, to, Icon, subItems }, i) => {
            return (
              <NavItem
                key={i}
                text={text}
                to={to}
                Icon={Icon}
                subItems={subItems}
              />
            );
          })}
          <ListItem disablePadding>
            <ListItemButton onClick={logout}>
              <ListItemIcon
                sx={{
                  color: "grey.700",
                  width: 34,
                  alignItems: "center"
                }}
              >
                <LogOut size={16} />
              </ListItemIcon>
              <ListItemText
                primary={<Typography color="text.primary">Log out</Typography>}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

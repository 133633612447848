import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";
import BlueBannerLogo from "../assets/logos/BlueBanner.png";
import { ReactComponent as OnboardingBg1 } from "../assets/onboarding-bg1.svg";

export const SignupLayout: FC<{
  bgType: "content" | "pattern";
  children: ReactNode;
}> = ({ bgType, children }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction="row"
      pl={{ xs: 5, sm: 10, md: 20, lg: 30 }}
      gap={{ xs: 5, sm: 5, md: 10, lg: 15 }}
    >
      <style>
        {`
          .grecaptcha-badge {
            bottom: auto !important;
            top: 4px !important;
          }
        `}
      </style>
      <Grid container direction="column" xs={11} md={4} my={5}>
        <Grid item>
          <img src={BlueBannerLogo} alt="HeathNow logo" height={28} />
        </Grid>
        <Grid container item mt={14}>
          {children}
        </Grid>
      </Grid>
      {bgType === "content" ? (
        <Grid
          container
          item
          direction="column"
          display={{ xs: "none", md: "inherit" }}
          xs={6}
          md={5}
          my={5}
          justifyContent="center"
          bgcolor={theme.palette.primary.main}
          borderRadius={3}
          height="90vh"
          color="white"
        >
          <Box display="flex" flexDirection="column" gap={3} mx={10}>
            <Box display="flex" alignItems="center" gap={1}>
              <CheckCircleOutlineOutlined />
              <Typography>
                {" "}
                Lorem ipsum dolor sit amet consectetur elit.{" "}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <CheckCircleOutlineOutlined />
              <Typography>
                {" "}
                Lorem ipsum dolor sit amet consectetur elit.{" "}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <CheckCircleOutlineOutlined />
              <Typography>
                {" "}
                Lorem ipsum dolor sit amet consectetur elit.{" "}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <CheckCircleOutlineOutlined />
              <Typography>
                {" "}
                Lorem ipsum dolor sit amet consectetur elit.{" "}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <CheckCircleOutlineOutlined />
              <Typography>
                {" "}
                Lorem ipsum dolor sit amet consectetur elit.{" "}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid
          height="100vh"
          item
          xs={6}
          display={{ xs: "none", md: "initial" }}
        >
          <OnboardingBg1 color={theme.palette.primary.light} />
        </Grid>
      )}
    </Grid>
  );
};

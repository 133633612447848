import { Button, Grid, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { CreatePlanInput } from "../../plans/AddPlan";

export const Step0Name: FC<{
  formik: FormikProps<CreatePlanInput>;
  next: () => void;
}> = ({ formik, next }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        Name your Employee Account
      </Typography>
      <Typography variant="subtitle1" my={1}>
        Employee Accounts are contributions for your staff to spend.
      </Typography>
      <TextField
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        placeholder="Primary Care etc."
        fullWidth
        size="small"
        variant="standard"
        inputProps={{
          style: { fontSize: 24, marginTop: 18, marginBottom: 10 }
        }}
      />
      <Button
        disabled={!formik.values.name || Boolean(formik.errors.name)}
        variant="contained"
        onClick={next}
        sx={{ marginTop: 30 }}
      >
        Continue
      </Button>
    </Grid>
  );
};

import { Button, Grid, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { EmployerFormValuesType } from "../../shared/EmployerForm";

export const Step2CompanyName: FC<{
  formik: FormikProps<EmployerFormValuesType>;
  next: () => void;
}> = ({ formik, next }) => {
  return (
    <>
      <Grid item>
        <Typography fontSize={28} variant="h4" my={1}>
          Thanks {formik.values.contactFirstName}!
        </Typography>
        <Typography variant="h4" fontSize={32} fontWeight={700}>
          What is your company name?
        </Typography>
      </Grid>

      <Grid container item xs={12} gap={2} my={3}>
        <TextField
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          placeholder="HealthNow etc."
          fullWidth
          size="small"
          variant="standard"
          inputProps={{
            style: {
              fontSize: 24,
              marginBottom: 10,
              borderWidth: "2px",
              outlineWidth: "5px"
            }
          }}
        />
        <Button
          disabled={!formik.values.name || Boolean(formik.errors.name)}
          variant="contained"
          sx={{ width: "100%", py: 1, mt: 5 }}
          onClick={next}
        >
          Continue
        </Button>
      </Grid>
    </>
  );
};

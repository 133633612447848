import { CheckCircle } from "@mui/icons-material";
import { Box, Checkbox, Typography, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";

export const AccountRadioBox: FC<{
  title: string;
  content: string;
  isSelected: boolean;
  onClick: () => void;
  children?: ReactNode;
  displayCheck?: boolean;
}> = ({ title, content, isSelected, onClick, children, displayCheck }) => {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      sx={{
        p: 1,
        m: 0,
        border: "2px solid black",
        borderColor: theme.palette.primary.light,
        borderRadius: "10px",
        mb: 2,
        color: isSelected ? "white" : "black",
        bgcolor: isSelected ? theme.palette.primary.light : "transparent",
        cursor: "pointer",
        position: "relative"
      }}
    >
      <Box onClick={onClick}>
        {isSelected && displayCheck && (
          <Box position="absolute" top={10} right={10}>
            <CheckCircle sx={{ fontSize: 30 }} />
          </Box>
        )}

        {!displayCheck && (
          <Checkbox
            sx={{
              padding: 0.5,
              "& .MuiSvgIcon-root": {
                fontSize: 55,
                borderWidth: 1,
                stroke: "#FFFFFF",
                strokeWidth: 1,
                borderColor: isSelected ? "white" : "black",
                color: isSelected ? "white" : theme.palette.primary.light
              }
            }}
            checked={isSelected}
          />
        )}
        <Typography fontSize={18} fontWeight={600} ml={1}>
          {title}
        </Typography>
        <Typography fontSize={14} ml={1} my={0.5}>
          {content}
        </Typography>
      </Box>

      <Box ml={1} my={1}>
        {children}
      </Box>
    </Box>
  );
};

import React, { useState, useEffect } from "react";
import { TextField, Button, Box, List, ListItem, ListItemText, IconButton, Select, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from "yup";
import { InvitedEmployee, Plan, PlanReference } from "../../models";

interface EmployeeInputProps {
  onEmployeesChange: (employees: InvitedEmployee[]) => void;
  invitedEmployees?: InvitedEmployee[];
  plans: Plan[];
}

const emailValidation = yup
  .string()
  .email("Invalid email")
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format")
  .test(
    "not-invalid",
    "Email cannot contain special characters in invalid positions",
    (value) => !/^\S+\*|\S+@-/.test(value ?? "")
  )
  .required("Email is required");

const firstNameValidation = yup
  .string()
  .required("First name is required");

const lastNameValidation = yup
  .string()
  .required("Last name is required");

const combinedNameLengthValidation = yup
  .object({
    firstName: yup.string(),
    lastName: yup.string(),
  })
  .test(
    "name-length",
    "First Name and Last Name combined must be less than 27 characters",
    (value) => {
      const combinedLength = (value?.firstName ?? "").length + (value?.lastName ?? "").length;
      return combinedLength < 27;
    }
  );

const EmployeeInput: React.FC<EmployeeInputProps> = ({
                                                       onEmployeesChange,
                                                       invitedEmployees = [],
                                                       plans
                                                     }) => {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [employees, setEmployees] = useState<InvitedEmployee[]>([]);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [nameLengthError, setNameLengthError] = useState<string | null>(null);

  useEffect(() => {
    setEmployees(invitedEmployees);
  }, [invitedEmployees]);

  const validateEmail = async (email: string): Promise<boolean> => {
    try {
      await emailValidation.validate(email);
      setEmailError(null);
      return true;
    } catch (error: any) {
      setEmailError(error.message);
      return false;
    }
  };

  const validateFirstName = async (firstName: string): Promise<boolean> => {
    try {
      await firstNameValidation.validate(firstName);
      setFirstNameError(null);
      return true;
    } catch (error: any) {
      setFirstNameError(error.message);
      return false;
    }
  };

  const validateLastName = async (lastName: string): Promise<boolean> => {
    try {
      await lastNameValidation.validate(lastName);
      setLastNameError(null);
      return true;
    } catch (error: any) {
      setLastNameError(error.message);
      return false;
    }
  };

  const validateNameLength = async (firstName: string, lastName: string): Promise<boolean> => {
    try {
      await combinedNameLengthValidation.validate({ firstName, lastName });
      setNameLengthError(null);
      return true;
    } catch (error: any) {
      setNameLengthError(error.message);
      return false;
    }
  };

  const handleAddEmployee = async () => {
    const isEmailValid = await validateEmail(email);
    const isFirstNameValid = await validateFirstName(firstName);
    const isLastNameValid = await validateLastName(lastName);
    const isNameLengthValid = await validateNameLength(firstName, lastName);

    if (isEmailValid && isFirstNameValid && isLastNameValid && isNameLengthValid) {
      if (employees.some((employee) => employee.email === email)) {
        setEmailError("Duplicate email address.");
        return;
      }

      const defaultPlanReferences: PlanReference[] = plans.map(plan => ({
        planId: plan.id,
        tierId: plan.tiers[0].id!
      }));

      const newEmployee: InvitedEmployee = {
        email,
        firstName,
        lastName,
        plans: defaultPlanReferences
      };

      const updatedEmployees = [...employees, newEmployee];
      setEmployees(updatedEmployees);
      onEmployeesChange(updatedEmployees);
      setEmail("");
      setFirstName("");
      setLastName("");
      setEmailError(null);
      setFirstNameError(null);
      setLastNameError(null);
      setNameLengthError(null);
    }
  };

  const handleDeleteEmployee = (emailToDelete: string) => {
    const updatedEmployees = employees.filter((employee) => employee.email !== emailToDelete);
    setEmployees(updatedEmployees);
    onEmployeesChange(updatedEmployees);
  };

  const handleTierChange = (employeeIndex: number, planIndex: number, tierId: string) => {
    const updatedEmployees = [...employees];
    updatedEmployees[employeeIndex].plans[planIndex].tierId = tierId;
    setEmployees(updatedEmployees);
    onEmployeesChange(updatedEmployees);
  };

  return (
    <div>
      <TextField
        name="firstName"
        placeholder="First Name"
        fullWidth
        size="small"
        variant="standard"
        inputProps={{
          style: { fontSize: 18, marginTop: 10, marginBottom: 10 }
        }}
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        style={{ marginBottom: 16 }}
        error={!!firstNameError || !!nameLengthError}
        helperText={firstNameError || nameLengthError}
      />
      <TextField
        name="lastName"
        placeholder="Last Name"
        fullWidth
        size="small"
        variant="standard"
        inputProps={{
          style: { fontSize: 18, marginTop: 10, marginBottom: 10 }
        }}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        style={{ marginBottom: 16 }}
        error={!!lastNameError || !!nameLengthError}
        helperText={lastNameError || nameLengthError}
      />
      <TextField
        name="email"
        placeholder="Email"
        fullWidth
        size="small"
        variant="standard"
        inputProps={{
          style: { fontSize: 18, marginTop: 10, marginBottom: 10 }
        }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ marginBottom: 16 }}
        error={!!emailError}
        helperText={emailError}
      />
      <Button variant="contained" color="primary" onClick={handleAddEmployee} style={{ marginTop: 16 }}>
        Add
      </Button>
      <Box sx={{ marginTop: 2 }}>
        <List>
          {employees.map((employee, employeeIndex) => (
            <ListItem key={employeeIndex} secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteEmployee(employee.email)}>
                <DeleteIcon />
              </IconButton>
            }>
              <ListItemText
                primary={`${employee.firstName} ${employee.lastName}`}
                secondary={employee.email}
              />
              {employee.plans.map((planReference, planIndex) => (
                <Select
                  key={planReference.planId}
                  value={planReference.tierId}
                  onChange={(e) => handleTierChange(employeeIndex, planIndex, e.target.value as string)}
                  displayEmpty
                  style={{ marginLeft: 8 }}
                >
                  {plans.find(plan => plan.id === planReference.planId)?.tiers.map((tier) => (
                    <MenuItem key={tier.id} value={tier.id}>
                      {plans.find(plan => plan.id === planReference.planId)?.name} - {tier.name}
                    </MenuItem>
                  ))}
                </Select>
              ))}
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );
};

export default EmployeeInput;

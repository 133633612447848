import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { FormEvent, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Employee } from "../../models";
import { checkJobProgress } from "../../utils/checkJobProgress";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";

type UnAssignToPlanProps = {
  open: boolean;
  handleComplete: () => void;
  handleClose: () => void;
  selectedEmployees: Employee[];
};

export const UnAssignPlans = ({
                                open,
                                handleComplete,
                                handleClose,
                                selectedEmployees
                              }: UnAssignToPlanProps) => {
  const { config } = useConfig();
  const { employerId, employees, plans, accounts } = useData();
  const { getToken } = useKindeAuth();

  const [selectedPlanIds, setSelectedPlanIds] = useState<string[]>([]);
  const [error, setError] = useState("");

  const selectedEmployeesWithPlan = selectedEmployees.filter(
    (employee) => employee.accounts.length !== 0
  );

  const assignAsynchronously = useMutation({
    mutationFn: async () => {
      canSelectedPlansBeUnAssigned();

      // Initialize a progress toast
      let toastId = toast("Un-Assigning Plans...", {
        progress: 0,
        autoClose: false,
        type: "info"
      });

      const selectedEmployeesIds = selectedEmployees.map((x) => x.id);
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/employees/accounts`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            employeeIds: selectedEmployeesIds,
            planIds: selectedPlanIds
          })
        }
      );

      if (!response.ok) {
        throw new Error("There was a problem un-assigning these plans");
      }

      const responseBody = await response.json();

      checkJobProgress(
        config!.API_URL,
        responseBody.jobId,
        toastId,
        "Plans have been removed!",
        getToken,
        onJobComplete
      );
    },
    onSuccess: () => {
      toast.success("Plans will be un-assigned in background!");
      employees.refetch();
      accounts.refetch();
      handleClose();
      handleComplete();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  if (accounts.isLoading) {
    return <>Loading...</>;
  }

  const accountsWithSelectedPlans = accounts.data!.items
    .filter(account => selectedPlanIds.includes(account.planId))
    .filter(account => selectedEmployees.find((employee) => account.employeeId === employee.id));

  const canSelectedPlansBeUnAssigned = () => {

    if (accountsWithSelectedPlans.length === 0) {
      throw new Error(
        "None of the selected employees are assigned the plan(s) that were selected to un-assign."
      );
    }

    if (accountsWithSelectedPlans.find((a) => a.availableBalance.amount < a.startingBalance.amount)) {
      throw new Error(
        "Cannot un-assign a plan from an employee that already has spent from this plan."
      );
    }
  };

  const onJobComplete = async () => {
    await employees.refetch();
    await accounts.refetch();
    handleClose();
  };

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    assignAsynchronously.mutate();
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>
            Un-Assign {selectedEmployeesWithPlan.length} user
            {selectedEmployeesWithPlan.length > 1 ? "s" : ""} plan(s)
          </DialogTitle>
        </Box>
        <DialogContent>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            <Grid item>
              <Typography>
                General instructions on un-assigning plans
              </Typography>
              <Typography color="gray">
                Only users without any spend on a plan can have their plans
                un-assigned.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} my={1}>
            <Grid item xs={12}>
              <Typography my={1}>Plans to Un-Assign</Typography>
              <Autocomplete
                size="small"
                multiple
                options={plans.data?.items ?? []}
                getOptionLabel={(option) => option.name}
                value={plans.data?.items.filter((plan) =>
                  selectedPlanIds.includes(plan.id)
                )}
                onChange={(_, newValue) => {
                  let newIds = newValue.map((plan) => plan.id);
                  setSelectedPlanIds(newIds);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} placeholder="Plans to Un-Assign" />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" color="primary">
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

import { RadioButtonChecked } from "@mui/icons-material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Button,
  Checkbox,
  Grid,
  RadioGroup,
  Typography,
  useTheme
} from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { EmployerCompanySize } from "../../../models";
import { EmployerFormValuesType } from "../../shared/EmployerForm";

export const Step3CompanySize: FC<{
  formik: FormikProps<EmployerFormValuesType>;
  next: () => void;
}> = ({ formik, next }) => {
  const theme = useTheme();
  const companySizeOptions = [
    { label: "1-350 Employees", value: EmployerCompanySize.SMALL },
    { label: "351-1000 Employees", value: EmployerCompanySize.MEDIUM },
    { label: "1000+ Employees", value: EmployerCompanySize.LARGE }
  ];

  return (
    <>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        How many people work at {formik.values.name}?
      </Typography>
      <Typography variant="subtitle1" my={1}>
        This helps us create a personalised experience
      </Typography>
      <Grid container item xs={12} gap={2} my={3}>
        <RadioGroup
          sx={{ width: "100%" }}
          name="companySize"
          value={formik.values.companySize}
        >
          {companySizeOptions.map((cso, i) => {
            const isSelected = cso.value === formik.values.companySize;
            return (
              <Grid
                key={i}
                container
                alignItems="center"
                direction="row"
                onClick={() => formik.setFieldValue("companySize", cso.value)}
                sx={{
                  p: 0,
                  m: 0,
                  border: "2px solid black",
                  borderColor: theme.palette.primary.light,
                  borderRadius: "10px",
                  mb: 2,
                  width: "100%",
                  color: isSelected ? "white" : "black",
                  bgcolor: isSelected
                    ? theme.palette.primary.light
                    : "transparent",
                  cursor: "pointer",
                  position: "relative"
                }}
              >
                <Checkbox
                  sx={{
                    padding: 0.5,
                    "& .MuiSvgIcon-root": {
                      fontSize: 55,
                      borderWidth: 1,
                      stroke: "#FFFFFF",
                      strokeWidth: 1,
                      borderColor: isSelected ? "white" : "black",
                      color: isSelected ? "white" : theme.palette.primary.light
                    }
                  }}
                  checked={isSelected}
                />
                <Typography fontSize={18} ml={1}>
                  {cso.label}
                </Typography>
                {isSelected ? (
                  <RadioButtonChecked
                    sx={{
                      color: "white",
                      position: "absolute",
                      top: 0,
                      right: 0,
                      margin: 1
                    }}
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    sx={{
                      color: theme.palette.primary.light,
                      position: "absolute",
                      top: 0,
                      right: 0,
                      margin: 1
                    }}
                  />
                )}
              </Grid>
            );
          })}
        </RadioGroup>
        <Button
          disabled={Boolean(formik.errors.companySize)}
          variant="contained"
          sx={{ width: "100%", py: 1, mt: 5 }}
          onClick={next}
        >
          Continue
        </Button>
      </Grid>
    </>
  );
};

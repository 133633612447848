import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery, UseQueryOptions } from "react-query";
import {
  Card,
  Employee,
  EmployeeAccount,
  EmployeeStatus,
  Employer,
  Feature,
  Float,
  Invoice,
  Ledger,
  Membership,
  MessageTemplate,
  Pagination,
  Plan,
  RegionalHealthNowProperties,
  ServiceSector,
  TagCategory,
  Transaction,
  UserInfo
} from "../models";
import { Communication } from "../models/communications/communication";
import { CommunicationPlatform } from "../models/communications/communicationPlatform";
import { Report } from "../models/reporting/report";
import { fetchData } from "./fetchData";
import { useConfig } from "./useConfig";

interface QueryOptions<TData> {
  endpoint: string;
  queryString?: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
  queryKey?: string;
  options?: UseQueryOptions<TData>;
}

function useCustomQuery<TData>(options: QueryOptions<TData>) {
  const {
    endpoint,
    queryString,
    pagination,
    queryKey,
    options: customOptions
  } = options;
  const { getToken } = useKindeAuth();
  const { config } = useConfig();

  const query = useQuery<TData>(
    queryKey || endpoint,
    () =>
      fetchData(
        `${config?.API_URL}${endpoint}`,
        getToken,
        queryString,
        pagination
      ),
    {
      ...customOptions,
      enabled: customOptions?.enabled !== false && !!config,
      keepPreviousData: true
    }
  );

  return query;
}

export function useFeatures() {
  const { config } = useConfig();
  const features = useQuery<Feature[]>(
    `/features`,
    () => fetchData(`${config?.API_URL}/features`),
    {
      keepPreviousData: true
    }
  );

  return features;
}

export function useMemberships() {
  const { config } = useConfig();
  const memberships = useQuery<Pagination<Membership>>(
    `/memberships`,
    () => fetchData(`${config?.API_URL}/memberships`),
    {
      keepPreviousData: true
    }
  );

  return memberships;
}

export function useData() {
  const { getToken, getClaim } = useKindeAuth();
  const { config } = useConfig();

  const employerId = getClaim("external_org_id")?.value;

  const { data: userInfoData } = useQuery<UserInfo>(
    "/user-info",
    () => fetchData(`${config?.API_URL}/user-info`, getToken),
    {
      enabled: !!config
    }
  );

  const employer = useCustomQuery<Employer>({
    endpoint: `/employers/${employerId}`,
    options: {
      enabled: !!employerId
    }
  });

  const float = useCustomQuery<Float>({
    endpoint: `/employers/${employerId}/float`,
    options: {
      enabled: !!employerId
    }
  });

  // Includes deleted employees
  const allEmployees = useCustomQuery<Pagination<Employee>>({
    endpoint: `/employers/${employerId}/employees`,
    queryString: `ignoreDeleted=false`,
    pagination: {
      page: 0,
      pageSize: 10000
    },
    options: {
      enabled: !!employerId
    }
  });

  // Doesn't include deleted employees
  let employees = allEmployees.data
    ? {
        ...allEmployees,
        data: {
          ...allEmployees.data,
          items: allEmployees.data.items.filter(
            (e) => !e.deletedAt && e.status !== EmployeeStatus.Deleted
          )
        }
      }
    : { ...allEmployees };

  // Includes deleted employees
  const accounts = useCustomQuery<Pagination<EmployeeAccount>>({
    endpoint: `/employers/${employerId}/employees/accounts`,
    queryString: `ignoreDeleted=true`,
    pagination: {
      page: 0,
      pageSize: 20000
    },
    options: {
      enabled: !!employerId
    }
  });

  const plans = useCustomQuery<Pagination<Plan>>({
    endpoint: `/employers/${employerId}/plans`,
    pagination: {
      page: 0,
      pageSize: 1000
    },
    options: {
      enabled: !!employerId
    }
  });

  const reports = useCustomQuery<Pagination<Report>>({
    endpoint: `/employers/${employerId}/reports`,
    pagination: {
      page: 0,
      pageSize: 1000
    },
    options: {
      enabled: !!employerId
    }
  });

  const cards = useCustomQuery<Pagination<Card>>({
    endpoint: `/employers/${employerId}/cards`,
    pagination: {
      page: 0,
      pageSize: 1000
    },
    options: {
      enabled: !!employerId
    }
  });

  const invoices = useCustomQuery<Pagination<Invoice>>({
    endpoint: `/employers/${employerId}/invoices`,
    pagination: {
      page: 0,
      pageSize: 500
    },
    options: {
      enabled: !!employerId
    }
  });

  const serviceSectors = useCustomQuery<Pagination<ServiceSector>>({
    endpoint: `/service-sectors`,
    queryString: `employerid=${employerId}`,
    options: {
      enabled: !!employerId
    }
  });

  const tagCategories = useCustomQuery<Pagination<TagCategory>>({
    endpoint: `/employers/${employerId}/tag-categories`,
    options: {
      enabled: !!employerId
    }
  });

  const messageTemplates = useCustomQuery<MessageTemplate[]>({
    endpoint: `/message-templates`
  });

  const healthNowProperties = useCustomQuery<RegionalHealthNowProperties>({
    endpoint: `/employers/${employerId}/healthnow-properties`,
    options: {
      enabled: !!employerId
    }
  });

  const transactions = useCustomQuery<Pagination<Transaction>>({
    endpoint: `/transactions`,
    queryString: `employerid=${employerId}`,
    pagination: {
      page: 0,
      pageSize: 10000
    },
    options: {
      enabled: !!employerId
    }
  });

  // Only getting Employee transactional data, no top-ups or expiries
  const ledgers = useCustomQuery<Pagination<Ledger>>({
    endpoint: `/ledger`,
    queryString: `employerid=${employerId}&type=PURCHASE&type=ADJUSTMENT&type=REFUND`,
    pagination: {
      page: 0,
      pageSize: 10000
    },
    options: {
      enabled: !!employerId
    }
  });

  const membership = useCustomQuery<Membership>({
    endpoint: `/employers/${employerId}/membership`,
    options: {
      enabled: !!employerId
    }
  });

  const communications = useCustomQuery<Pagination<Communication>>({
    endpoint: `/employers/${employerId}/employees/${userInfoData?.employeeId}/communications?platform=${CommunicationPlatform.EMPLOYER_WEB}`,
    options: {
      enabled: !!userInfoData && !!employerId
    }
  });

  return {
    employerId,
    employer,
    allEmployees,
    employees,
    accounts,
    invoices,
    plans,
    reports,
    cards,
    serviceSectors,
    userInfoData: {
      ...userInfoData,
      user: employees.data?.items.find(
        (employee) => employee.id === userInfoData?.employeeId
      )
    } as UserInfo | undefined,
    tagCategories,
    messageTemplates,
    healthNowProperties,
    transactions,
    ledgers,
    membership,
    float,
    communications
  };
}

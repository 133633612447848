import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { FormikProps } from "formik";
import { FC, useEffect, useState } from "react";
import { ServiceSector } from "../../../models";
import { CreatePlanInput } from "../../plans/AddPlan";
import { BackButton } from "../../shared/BackButton";
import { AccountRadioBox } from "../AccountRadioBox";

const RestrictedOption = "restricted";
const OpenOption = "open";

export const getOpenServiceSector = (serviceSectors: ServiceSector[]) => {
  return serviceSectors?.find(
    (ss) =>
      ss.restrictions.mccCodes.allow.length === 0 &&
      ss.restrictions.mccCodes.block.length === 0 &&
      ss.restrictions.providerNames.allow.length === 0 &&
      ss.restrictions.providerNames.block.length === 0
  );
};

export const Step3ServiceSectors: FC<{
  formik: FormikProps<CreatePlanInput>;
  serviceSectors: ServiceSector[] | undefined;
  next: () => void;
  back: () => void;
}> = ({ formik, serviceSectors, next, back }) => {
  const openServiceSector = getOpenServiceSector(serviceSectors ?? []);
  const restrictedServiceSectors = serviceSectors?.filter(
    (x) => x.id !== openServiceSector?.id
  );

  const [selectedOption, setSelectedOption] = useState<
    "open" | "restricted" | undefined
  >(undefined);

  // When loads we need to figure out what option they have selected
  useEffect(() => {
    if (
      formik.values.serviceSectorIds &&
      formik.values.serviceSectorIds[0] ===
        getOpenServiceSector(serviceSectors ?? [])?.id
    ) {
      setSelectedOption(OpenOption);
    } else {
      setSelectedOption(RestrictedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12}>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        Choose your Benefits{" "}
      </Typography>
      <Typography variant="subtitle1" my={2}>
        Assign where your staff can spend their set allowance. Most businesses
        allow staff to spend their allowance at all healthcare providers the
        card is accepted. This give staff the best possible healthcare benefits
        offered by HealthNow
      </Typography>

      <Typography variant="subtitle1" my={2} color="primary" fontWeight={500}>
        Choose where your team can use their health allowance
      </Typography>

      <Box>
        <AccountRadioBox
          title="Restricted"
          content="Pick and choose exactly where staff can spend their contribution"
          isSelected={selectedOption === RestrictedOption}
          onClick={() => {
            if (selectedOption !== RestrictedOption) {
              setSelectedOption(RestrictedOption);
              formik.setFieldValue("serviceSectorIds", []);
            }
          }}
          displayCheck={true}
          children={
            selectedOption === RestrictedOption && (
              <>
                <Autocomplete
                  disabled={selectedOption !== RestrictedOption}
                  multiple
                  options={restrictedServiceSectors ?? []}
                  getOptionLabel={(option) => option.name}
                  value={restrictedServiceSectors?.filter((sector) =>
                    formik.values.serviceSectorIds?.includes(sector.id)
                  )}
                  onChange={(_, newValue) => {
                    let newIds = newValue.map((sector) => sector.id);
                    formik.setFieldValue("serviceSectorIds", newIds);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Categories"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          overflowY: "auto",
                          minHeight: 60,
                          height: "auto",
                          paddingLeft: 10,
                          paddingRight: 10,
                          backgroundColor: "white"
                        }
                      }}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        style: {
                          overflowY: "auto",
                          minHeight: 60,
                          paddingLeft: 10,
                          paddingRight: 10
                        }
                      }}
                    />
                  )}
                />
                {/* Display an error message if there are no service sector ids */}
                {formik.errors.serviceSectorIds && (
                  <Typography color="error" sx={{ mt: 2 }}>
                    {formik.errors.serviceSectorIds}
                  </Typography>
                )}
              </>
            )
          }
        />
        {openServiceSector && (
          <AccountRadioBox
            title="All Categories"
            content="Staff can spend anywhere"
            isSelected={selectedOption === OpenOption}
            onClick={() => {
              setSelectedOption(OpenOption);
              formik.setFieldValue("serviceSectorIds", [openServiceSector.id]);
            }}
            displayCheck={true}
          />
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 10 }}
      >
        <BackButton onClick={back} />
        <Button
          disabled={
            !formik.values.serviceSectorIds.length ||
            Boolean(formik.errors.serviceSectorIds)
          }
          variant="contained"
          onClick={next}
        >
          Continue
        </Button>
      </Box>
    </Grid>
  );
};

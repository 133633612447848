import { Autorenew, HistoryOutlined } from "@mui/icons-material";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { PlanType, RenewalStrategy, ServiceSector } from "../../models";
import { CreatePlanInput } from "../plans/AddPlan";
import { getOpenServiceSector } from "./EmployeeAccountSetupSteps/3_serviceSectors";
import { calculateYearlyCost, resolveRenewalPeriod } from "./TierInfoBox";

export const AccountSummary: FC<{
  variant: "full" | "preview";
  formik: FormikProps<CreatePlanInput>;
  serviceSectors: ServiceSector[] | undefined;
}> = ({ variant, formik, serviceSectors }) => {
  const theme = useTheme();
  const openServiceSector = getOpenServiceSector(serviceSectors ?? []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      sx={{
        padding: 2,
        borderWidth: 2,
        bgcolor: variant === "full" ? "transparent" : theme.palette.grey[200],
        borderColor:
          variant === "full"
            ? theme.palette.primary.light
            : theme.palette.grey[200],
        borderStyle: "solid",
        borderRadius: 2
      }}
    >
      {/* NAME */}
      <Typography fontSize={20} fontWeight={600}>
        {formik.values.name}
      </Typography>
      <Divider
        sx={{
          borderWidth: variant === "full" ? 1 : 0.5,
          borderColor:
            variant === "full"
              ? theme.palette.grey[200]
              : theme.palette.grey[300]
        }}
      />

      {/* RENEWAL */}
      {formik.values.planType &&
        formik.values.renewalOptions.periodInMonths && (
          <Box display="flex" gap={0.5} alignItems="center" mt={1}>
            <Autorenew sx={{ color: theme.palette.grey[600] }} />
            {formik.values.planType === PlanType.ONGOING ? (
              <Typography fontSize={14} color={theme.palette.grey[600]}>
                Renews{" "}
                {formik.values.renewalOptions.periodInMonths === 1 && "Monthly"}
                {formik.values.renewalOptions.periodInMonths === 6 &&
                  "Bi-Annually"}
                {formik.values.renewalOptions.periodInMonths === 12 && "Yearly"}
              </Typography>
            ) : (
              <>
                <Typography fontSize={14} color={theme.palette.grey[600]}>
                  One-Off
                </Typography>
                <HistoryOutlined
                  sx={{ ml: 2, color: theme.palette.grey[600] }}
                />
                <Typography fontSize={14} color={theme.palette.grey[600]}>
                  {formik.values.renewalOptions.periodInMonths === 1 &&
                    "Expires in 1 Month"}
                  {formik.values.renewalOptions.periodInMonths === 6 &&
                    "Expires in 6 Months"}
                  {formik.values.renewalOptions.periodInMonths === 12 &&
                    "Expires in 1 Year"}
                </Typography>
              </>
            )}
          </Box>
        )}

      {/* TIERS */}
      {formik.values.tiers.length > 0 && (
        <>
          <Typography fontSize={18} fontWeight={600} mt={1}>
            Tiers
          </Typography>
          {formik.values.tiers.map((t, i) => {
            return (
              <Box
                key={i}
                display="flex"
                alignItems="flex-end"
                paddingX={2}
                paddingY={1}
                sx={{
                  borderWidth: variant === "full" ? 2 : 1,
                  borderColor:
                    variant === "full"
                      ? theme.palette.grey[200]
                      : theme.palette.grey[300],
                  borderStyle: "solid",
                  borderRadius: 1
                }}
              >
                <Typography fontWeight={600}>{t.name}</Typography>
                <Typography ml="auto">
                  ${t.amount.amount} /
                  {resolveRenewalPeriod(
                    formik.values.renewalOptions.periodInMonths ?? 0
                  )}
                </Typography>
                {formik.values.renewalOptions.periodInMonths !== 12 && (
                  <Typography
                    fontSize={12}
                    whiteSpace="nowrap"
                    ml={2}
                    mb={0.2}
                    color="grey.500"
                  >
                    $
                    {calculateYearlyCost(
                      formik.values.renewalOptions.periodInMonths ?? 0,
                      t.amount.amount
                    )}
                    /year
                  </Typography>
                )}
              </Box>
            );
          })}
        </>
      )}

      {/* SERVICE SECTORS */}
      {formik.values.serviceSectorIds.length > 0 && (
        <>
          <Typography fontSize={18} fontWeight={600} mt={1}>
            Benefits
          </Typography>
          <Box
            paddingX={2}
            paddingY={1}
            sx={{
              borderWidth: variant === "full" ? 2 : 1,
              borderColor:
                variant === "full"
                  ? theme.palette.grey[200]
                  : theme.palette.grey[300],
              borderStyle: "solid",
              borderRadius: 1
            }}
          >
            <Typography fontWeight={600}>
              {formik.values.serviceSectorIds.find(
                (ssid) => ssid === openServiceSector?.id
              )
                ? "All Categories"
                : "Restricted"}
            </Typography>
            <Typography fontSize={14} my={0.5}>
              {formik.values.serviceSectorIds.find(
                (ssid) => ssid === openServiceSector?.id
              )
                ? "Staff can spend anywhere"
                : "Staff can only spend their contribution at selected categories"}
            </Typography>
            {variant === "full" && (
              <Box display="flex" gap={1} flexWrap="wrap">
                {!formik.values.serviceSectorIds.find(
                  (ssid) => ssid === openServiceSector?.id
                ) &&
                  serviceSectors
                    ?.filter((ss) =>
                      formik.values.serviceSectorIds.find(
                        (ssid) => ssid === ss.id
                      )
                    )
                    .map((ss) => {
                      return (
                        <Box
                          mt={1}
                          whiteSpace="nowrap"
                          fontSize={14}
                          bgcolor={theme.palette.grey[200]}
                          p={1}
                          px={2}
                          borderRadius={1}
                        >
                          {ss.name}
                        </Box>
                      );
                    })}
              </Box>
            )}
          </Box>
        </>
      )}

      <Box
        display="flex"
        justifyContent={variant === "full" ? "normal" : "space-between"}
        gap={variant === "full" ? 10 : 0}
        mt={1}
      >
        <Box>
          <Typography fontWeight={600} fontSize={12}>
            ACCOUNT ACTIVATES
          </Typography>
          <Typography fontSize={14}>
            {formik.values.renewalOptions.startDate
              ? new Date(
                  formik.values.renewalOptions.startDate
                ).toLocaleDateString()
              : "Date Employee Added"}
          </Typography>
        </Box>
        {formik.values.planType === PlanType.ONGOING && (
          <Box>
            <Typography fontWeight={600} fontSize={12}>
              RESIDUAL BALANCE
            </Typography>
            <Typography fontSize={14}>
              {formik.values.renewalOptions.strategy === RenewalStrategy.RESET
                ? "Resets on Renewal"
                : "Rolls over"}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

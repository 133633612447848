import { Box, Typography } from "@mui/material";
import { Float } from "../../models";
import { formatCurrency } from "../../utils/formatters";
import { generateColorGradient } from "../../utils/generateColorGradient";
import MainCard from "../shared/MainCard";

type CurrentFloatViewProps = {
  float: Float;
};

export const CurrentFloatView = ({ float }: CurrentFloatViewProps) => {
  const colors = generateColorGradient(5);

  const displayInvoiceItem = (title: string, value: number, color: string) => {
    return (
      // @ts-ignore-next-line
      <MainCard
        content={false}
        sx={{
          width: "100%",
          backgroundColor: color,
          textAlign: "right",
          padding: 2
        }}
      >
        <Typography color="white">{title}</Typography>
        <Typography color="white" variant="h5" py={1}>
          {formatCurrency(value)}
        </Typography>
      </MainCard>
    );
  };

  return (
    <Box display="flex" gap={2} pt={2}>
      <Box flex={1}>
        {displayInvoiceItem(
          "Total Contribution",
          float.totalContribution.amount,
          colors[0]
        )}
        <Typography variant="subtitle2" my={1} color="grey.500">
          The aggregate spend amount your organisation has contributed and is
          available for your employees.
        </Typography>
      </Box>

      <Box flex={1}>
        {displayInvoiceItem(
          "Minimum Float Required",
          float.minimumAmount.amount,
          colors[1]
        )}
        <Typography variant="subtitle2" my={1} color="grey.500">
          {float.minimumFloatPercent * 100}% of the Total Contribution. This is
          the minimum amount we require as a bond for your card program.
        </Typography>
      </Box>

      <Box flex={1}>
        {displayInvoiceItem(
          "Available Float",
          float.availableAmount.amount,
          colors[3]
        )}
        <Typography variant="subtitle2" my={1} color="grey.500">
          Your float contribution less transactions value for the billing
          period. Paid invoices will contribute to the Available Float balance.
        </Typography>
      </Box>
    </Box>
  );
};

// material-ui
import { createTheme } from "@mui/material/styles";

// fonts
import JakartaSansBold from "../assets/fonts/PlusJakartaSans-Bold.ttf";
import JakartaSansLight from "../assets/fonts/PlusJakartaSans-Light.ttf";
import JakartaSansMedium from "../assets/fonts/PlusJakartaSans-Medium.ttf";
import JakartaSansRegular from "../assets/fonts/PlusJakartaSans-Regular.ttf";
import JakartaSansSemiBold from "../assets/fonts/PlusJakartaSans-SemiBold.ttf";

// third-party
import { PalettesProps, presetPalettes } from "@ant-design/colors";

export const generateTheme = () => {
  const colors = presetPalettes;

  const greyPrimary = [
    "#ffffff",
    "#fafafa",
    "#f5f5f5",
    "#f0f0f0",
    "#d9d9d9",
    "#bfbfbf",
    "#8c8c8c",
    "#595959",
    "#262626",
    "#141414",
    "#000000"
  ];
  const greyAscent = ["#fafafa", "#bfbfbf", "#434343", "#1f1f1f"];
  const greyConstant = ["#fafafb", "#e6ebf1"];

  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  const paletteColor = themeOption(colors);

  return createTheme({
    palette: {
      common: {
        black: "#000",
        white: "#fff"
      },
      ...paletteColor,
      text: {
        primary: paletteColor.grey[700],
        secondary: paletteColor.grey[500],
        disabled: paletteColor.grey[400]
      },
      action: {
        disabled: paletteColor.grey[300]
      },
      divider: paletteColor.grey[200],
      background: {
        paper: paletteColor.grey[0],
        default: paletteColor.grey.A50
      }
    },
    typography: {
      fontFamily: "'PlusJakartaSans', sans-serif"
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            fontWeight: 600,
            borderRadius: 6,
            height: 45
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            borderRadius: 6,
            height: 45
          },
          notchedOutline: {
            fontWeight: 500,
            borderRadius: 6
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'PlusJakartaSans';
            src: local('PlusJakartaSans'), local('PlusJakartaSans-Light'), url(${JakartaSansLight}) format('truetype');
            font-weight: 300;
          }
          @font-face {
            font-family: 'PlusJakartaSans';
            src: local('PlusJakartaSans'), local('PlusJakartaSans-Regular'), url(${JakartaSansRegular}) format('truetype');
            font-weight: 400;
          }
          @font-face {
            font-family: 'PlusJakartaSans';
            src: local('PlusJakartaSans'), local('PlusJakartaSans-Medium'), url(${JakartaSansMedium}) format('truetype');
            font-weight: 500;
          }
          @font-face {
            font-family: 'PlusJakartaSans';
            src: local('PlusJakartaSans'), local('PlusJakartaSans-SemiBold'), url(${JakartaSansSemiBold}) format('truetype');
            font-weight: 600;
          }
          @font-face {
            font-family: 'PlusJakartaSans';
            src: local('PlusJakartaSans'), local('PlusJakartaSans-Bold'), url(${JakartaSansBold}) format('truetype');
            font-weight: 700;
          }
        `
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: "5px"
          },
          colorPrimary: {
            backgroundColor: "#015450"
          },
          colorSecondary: {
            backgroundColor: "#00ABA3"
          },
          colorError: {
            backgroundColor: "#FC806F"
          },
          colorWarning: {
            backgroundColor: "#808080"
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "20px"
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "34px"
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            paddingLeft: "26px"
          }
        }
      }
    }
  });
};

const themeOption = (colors: PalettesProps) => {
  const { grey } = colors;
  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = "#fff";

  // HN
  // const primaryColors = [
  //   "#dae6e6",
  //   "#cededd",
  //   "#c2d5d5",
  //   "#9dbcbc",
  //   "#548a89",
  //   "#0A5857",
  //   "#094f4e",
  //   "#084241",
  //   "#063534",
  //   "#052b2b",
  // ];
  // #8
  const primaryColors = [
    "#c1d6d066",
    "#81c9b9",
    "#5bbda9",
    "#38b09c",
    "#1aa391",
    "#009688",
    "#007069",
    "#004a47",
    "#002424",
    "#000000"
  ];
  const errorColors = ["#FDE8E7", "#F25E52", "#F04134", "#EE3B2F", "#E92A21"];
  const warningColors = ["#FFF7E0", "#FFC926", "#FFBF00", "#FFB900", "#FFA900"];
  const infoColors = ["#E0F4F5", "#26B0BA", "#00A2AE", "#009AA7", "#008694"];
  const successColors = ["#E0F5EA", "#26B56E", "#00A854", "#00A04D", "#008D3A"];

  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      200: primaryColors[2],
      light: primaryColors[3],
      400: primaryColors[4],
      main: primaryColors[5],
      dark: primaryColors[6],
      700: primaryColors[7],
      darker: primaryColors[8],
      900: primaryColors[9],
      contrastText
    },
    secondary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: greyColors[300],
      400: greyColors[400],
      main: greyColors[500],
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[0]
    },
    error: {
      lighter: errorColors[0],
      light: errorColors[1],
      main: errorColors[2],
      dark: errorColors[3],
      darker: errorColors[4],
      contrastText
    },
    warning: {
      lighter: warningColors[0],
      light: warningColors[1],
      main: warningColors[2],
      dark: warningColors[3],
      darker: warningColors[4],
      contrastText: greyColors[100]
    },
    info: {
      lighter: infoColors[0],
      light: infoColors[1],
      main: infoColors[2],
      dark: infoColors[3],
      darker: infoColors[4],
      contrastText
    },
    success: {
      lighter: successColors[0],
      light: successColors[1],
      main: successColors[2],
      dark: successColors[3],
      darker: successColors[4],
      contrastText
    },
    grey: greyColors
  };
};

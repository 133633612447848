import { HelpOutline } from "@mui/icons-material";
import { Box, Divider, Tooltip, Typography, useTheme } from "@mui/material";

export const HelpAndSupportFaq = () => {
  const theme = useTheme();
  const faqData = [
    {
      label: "What is an Employee Account?",
      content: "TODO"
    },
    {
      label: "Should I create a monthly or annual account?",
      content: "TODO"
    },
    {
      label: "What are account tiers?",
      content: "TODO"
    },
    {
      label: "What is an Employee Account?",
      content: "TODO"
    },
    {
      label: "Should I create a monthly or annual account?",
      content: "TODO"
    },
    {
      label: "What are account tiers?",
      content: "TODO"
    }
  ];

  return (
    <Box bgcolor={theme.palette.grey[200]} borderRadius={2} p={2}>
      <Typography fontWeight={600} color={theme.palette.grey[600]}>
        Help & Support
      </Typography>
      <Divider sx={{ my: 1, borderColor: theme.palette.grey[300] }} />
      {faqData.map((fd, i) => {
        return (
          <Tooltip
            key={i}
            title={fd.content}
            sx={{ my: 1, cursor: "help" }}>
            <Box
              display="flex"
              alignItems="center"
              gap={0.5}
              color={theme.palette.grey[500]}
            >
              <HelpOutline />
              <Typography fontSize={14}>{fd.label}</Typography>
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};

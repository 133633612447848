import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Box, Button, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { Employer, Invoice, InvoiceStatus, InvoiceType } from "../../models";
import { useConfig } from "../../utils/useConfig";
import { LoadingSpinner } from "../shared/LoadingSpinner";
import { formatCurrency } from "../../utils/formatters";
import { BackButton } from "../shared/BackButton";
import { CardPayment } from "../billing/CardPayment";

export const Step4SetupFloat: FC<{
  employer: Employer | undefined;
  next: () => void;
  back: () => void;
  invoices: Invoice[];
  minimumFloat: number;
}> = ({ employer, next, back, invoices, minimumFloat }) => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();

  const [invoice, setInvoice] = useState<Invoice>();
  const [error, setError] = useState<string>();
  const [paid, setPaid] = useState<boolean>(!!invoices.find(x => x.status === InvoiceStatus.PAID));
  const hasCreatedInvoice = useRef(false); // Ref to track if mutation has been called

  const createFloatInvoice = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employer?.id}/invoices`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            type: InvoiceType.FLOAT,
            amount: minimumFloat
          })
        }
      );

      if (!response.ok) {
        throw new Error("There was a problem processing the payment");
      }

      const invoice = await response.json();
      setInvoice(invoice);
    },
    onSuccess: () => {
    },
    onError: (error: Error) => {
      console.log(error);
      console.error(error.message);
      setError(error.message);
    }
  });

  useEffect(() => {
    if (!paid && !hasCreatedInvoice.current) {
      createFloatInvoice.mutate();
      hasCreatedInvoice.current = true; // Set the flag to true after mutation is called
    }
  }, []);

  return (
    <Grid container item>
      <Grid item xs={8}>
        <Typography variant="h4" fontSize={32} fontWeight={700}>
          Add your Payment Details
        </Typography>

        <Typography mt={4} mb={2} fontWeight={600}>
          How Payment Works
        </Typography>

        <Divider sx={{ borderWidth: 1 }} />
        <List dense={true}>
          <ListItem>
            <Typography fontSize={15}>
              • We secure 10% of your employee allowance as credit to cover your
              staff transactions"
            </Typography>
          </ListItem>
          <ListItem>
            <Typography fontSize={15}>
              • Your credit is used to cover your staff transactions
            </Typography>
          </ListItem>
          <ListItem>
            <Typography fontSize={15}>
              • If your credit is depleted we secure another 10% to cover
              additional staff transactions{" "}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography fontSize={15}>
              • You will never be charged more than your set staff allowances
              giving you total peace of mind
            </Typography>
          </ListItem>
        </List>

        <Divider sx={{ borderWidth: 1 }} />

        <Box display="flex" my={2}>
          <Typography fontWeight={600}>Credit Due Today </Typography>
          <Typography ml={1}>(10% of Monthly Allowances)</Typography>
          <Typography ml="auto">{formatCurrency(minimumFloat)}</Typography>
        </Box>

        <Divider sx={{ borderWidth: 1 }} />
        <Box my={4}>
          <Typography fontWeight={600}>Card Details</Typography>
        </Box>

        {paid && <Typography fontWeight={600}>Successfully Paid</Typography>}

        {!paid &&
          <>
            {
              invoice ? (
                <CardPayment
                  invoiceId={invoice.id}
                  amount={invoice.amount}
                  onSuccess={next}
                />
              ) : (
                <LoadingSpinner marginY="10" />
              )
            }
          </>
        }

        {error && <Typography color="error">{error}</Typography>}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 5 }}
        >
          <BackButton onClick={back} />
          <Button
            variant="contained"
            disabled={!paid}
            onClick={next}
            sx={{ width: 310 }}
          >
            Complete
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

import { AccountCircleOutlined, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { FC, ReactNode, useEffect, useState } from "react";
import BlueBannerLogo from "../assets/logos/BlueBanner.png";
import { Employee, Employer } from "../models";

export const OnboardingLayout: FC<{
  step: number;
  subStep: number;
  totalSteps: number;
  employer: Employer | undefined;
  employee: Employee | undefined;
  children: ReactNode;
}> = ({ step, subStep, totalSteps, employer, employee, children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isSmallScreen) {
      setOpen(true);
    }
  }, [isSmallScreen]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Onboarding</DialogTitle>
        <DialogContent>
          <Typography>
            We recommend completing your onboarding on a desktop/tablet device,
            for the best possible experience!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        direction="row"
        my={3}
        px={{ xs: 5, sm: 10, md: 20, lg: 30 }}
        className={open ? "blur-background" : ""}
      >
        <style>
          {`
            .blur-background {
              filter: blur(50px);
            }
          `}
        </style>
        {/* Navbar/Header */}
        <Box display="flex" width="100%" alignItems="center" gap={2}>
          <Box>
            <img src={BlueBannerLogo} alt="HeathNow logo" height={28} />
          </Box>
          <Box ml="auto" display="flex" alignItems="center" gap={1}>
            <AccountCircleOutlined
              color="secondary"
              sx={{ width: 34, height: 34, stroke: "#ffffff", strokeWidth: 1 }}
            />
            <Box>
              <Typography fontSize={14} fontWeight={500} lineHeight={1}>
                {employee?.firstName} {employee?.lastName}
              </Typography>
              <Typography fontSize={12} fontWeight={400}>
                {employer?.name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Grid container item mt={3} xs={12}>
          {/* Steps counter */}
          {step !== 0 && (
            <Grid item my={5}>
              <Typography
                fontSize={14}
                color="secondary.main"
                fontWeight={600}
                display="flex"
                gap={0.5}
              >
                STEP{" "}
                <span style={{ color: theme.palette.secondary.dark }}>
                  {step}{" "}
                </span>
                OF{" "}
                <span style={{ color: theme.palette.secondary.dark }}>
                  {totalSteps}
                </span>
              </Typography>
              <Box display="flex" gap={2} my={2}>
                <Typography
                  color={step - 1 === 0 ? "primary" : "secondary"}
                  fontWeight={step - 1 === 0 ? 500 : 400}
                >
                  Create Healthcare Account
                </Typography>
                <ChevronRight color="secondary" />
                <Typography
                  color={step - 1 === 1 ? "primary" : "secondary"}
                  fontWeight={step - 1 === 1 ? 500 : 400}
                >
                  Invite Staff
                </Typography>
                <ChevronRight color="secondary" />
                <Typography
                  color={step - 1 === 2 ? "primary" : "secondary"}
                  fontWeight={step - 1 === 2 ? 500 : 400}
                >
                  Add Credit
                </Typography>
                <ChevronRight color="secondary" />
                <Typography
                  color={step - 1 === 3 ? "primary" : "secondary"}
                  fontWeight={step - 1 === 3 ? 500 : 400}
                >
                  Review & Complete
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid container item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

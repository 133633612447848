import { Box, Button, Grid, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { FormikProps } from "formik";
import { DateTime } from "luxon";
import { FC, useEffect } from "react";
import { RenewalType } from "../../../models";
import { CreatePlanInput } from "../../plans/AddPlan";
import { BackButton } from "../../shared/BackButton";
import { AccountRadioBox } from "../AccountRadioBox";

export const Step4RenewalType: FC<{
  formik: FormikProps<CreatePlanInput>;
  next: () => void;
  back: () => void;
}> = ({ formik, next, back }) => {
  useEffect(() => {
    if (formik.values.renewalOptions.type === RenewalType.SET_DATE) {
      formik.setFieldValue(
        "renewalOptions.startDate",
        DateTime.fromISO(new Date().toISOString())
      );
    } else {
      formik.setFieldValue("renewalOptions.startDate", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.renewalOptions.type]);

  return (
    <Grid item xs={12}>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        When do you want the account to activate?
      </Typography>
      <Box display="flex" width="100%" gap={3} my={4}>
        <AccountRadioBox
          title={"Date the employee is added"}
          content={"Starts when the employee accepts the invite"}
          isSelected={
            formik.values.renewalOptions.type ===
            RenewalType.INDIVIDUAL_START_DATE
          }
          onClick={() =>
            formik.setFieldValue(
              "renewalOptions.type",
              RenewalType.INDIVIDUAL_START_DATE
            )
          }
        />
        <AccountRadioBox
          title={"A specific date"}
          content={"Pick a date"}
          isSelected={
            formik.values.renewalOptions.type === RenewalType.SET_DATE
          }
          onClick={() =>
            formik.setFieldValue("renewalOptions.type", RenewalType.SET_DATE)
          }
        />
      </Box>

      {formik.values.renewalOptions.type === RenewalType.SET_DATE && (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            name="startDate"
            value={DateTime.fromISO(
              formik.values.renewalOptions.startDate ?? new Date().toISOString()
            )}
            onChange={(value) => formik.setFieldValue("renewalOptions.startDate", value)}
            slotProps={{
              textField: {
                helperText: formik.errors.renewalOptions?.startDate ? (
                  <Typography variant="caption" color="error">
                    {formik.errors.renewalOptions?.startDate}
                  </Typography>
                ) : null,
                error: Boolean(formik.errors.renewalOptions?.startDate),
                InputProps: {
                  style: {
                    borderColor: formik.errors.renewalOptions?.startDate
                      ? "red"
                      : undefined
                  }
                }
              }
            }}
            format={"dd/MM/yyyy"}
            sx={{ width: "100%" }}
          />
        </LocalizationProvider>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 10 }}
      >
        <BackButton onClick={back} />
        <Button
          disabled={
            formik.values.renewalOptions.type === RenewalType.SET_DATE &&
            !formik.values.renewalOptions.startDate
          }
          variant="contained"
          onClick={next}
        >
          Continue
        </Button>
      </Box>
    </Grid>
  );
};

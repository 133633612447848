import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useTheme
} from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { Fragment, useState } from "react";
import {
  CheckCircle,
  Circle,
  CreditCard,
  Download,
  MinusCircle,
  Send,
  Settings,
  Sliders,
  Tag as TagIcon,
  Upload,
  UserPlus
} from "react-feather";
import { useNavigate } from "react-router-dom";
import { HnChip } from "../../components/HnChip";
import { AddUser } from "../../components/employees/AddUser";
import { ApplyTags } from "../../components/employees/ApplyTags";
import { AssignPlans } from "../../components/employees/AssignPlans";
import { EditUser } from "../../components/employees/EditUser";
import { UnAssignPlans } from "../../components/employees/UnAssignPlans";
import { IssueCards } from "../../components/modals/IssueCards";
import { SendMessage } from "../../components/modals/SendMessage";
import { LightTooltip } from "../../components/shared/LightTooltip";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { Table } from "../../components/shared/Table";
import { CardStatus, Employee, EmployeeStatus, Tag } from "../../models";
import { ReportEntity } from "../../models/reporting/reportEntity";
import { convertEmployeesToCsv, downloadCsv } from "../../utils/convertToCSV";
import { useData } from "../../utils/useData";

export const Employees = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { employees, tagCategories, plans, membership, accounts } = useData();
  const [editingEmployee, setEditingEmployee] = useState<Employee>();
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [assignTagsModalOpen, setAssignTagsModalOpen] = useState(false);
  const [assignToPlanModalOpen, setAssignToPlanModalOpen] = useState(false);
  const [unAssignToPlanModalOpen, setUnAssignToPlanModalOpen] = useState(false);
  const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);
  const [issueCardModalOpen, setIssueCardModalOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);

  // After table actions are completed (assigning tags, etc), we want to reset the table selection.
  // If we reset the row selection, it handles this visually but the table state still has the rows selected.
  // Easiest way to handle resetting the table entirely via updating the table key.
  const [tableResetKey, setTableResetKey] = useState(0);

  const handleResetTableSelection = () => {
    setTableResetKey(tableResetKey + 1);
  };

  const handleDownloadCsv = (employees: Employee[]) => {
    const csv = convertEmployeesToCsv(
      employees,
      accounts.data?.items ?? [],
      tagCategories.data?.items,
      plans.data?.items
    );
    downloadCsv(csv, `${ReportEntity.EMPLOYEES}${new Date().toLocaleString()}`);
  };

  const columns: ColumnDef<Employee>[] = [
    {
      accessorFn: (row) => `${row.firstName} ${row.lastName}`,
      header: "Name",
      sortingFn: (rowA, rowB) => {
        return rowA.original.lastName < rowB.original.lastName
          ? -1
          : rowA.original.lastName === rowB.original.lastName
          ? 0
          : 1;
      }
    },
    {
      accessorFn: (row) => {
        row.tags = row.tags.sort(compareTags);
        return row;
      },
      header: "Tags",
      cell: (cell) => {
        const employee = cell.getValue() as Employee;
        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            minHeight={75}
            gap={1}
          >
            <Box>
              <Typography>
                {`${employee.firstName} ${employee.lastName}`}{" "}
              </Typography>
              <u style={{ color: theme.palette.grey[500], fontSize: 12 }}>
                {employee.contact.email}
              </u>
            </Box>

            {employee.tags.length > 0 && (
              <Box
                display="flex"
                maxWidth={250}
                sx={{
                  overflowX: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none" // for Chrome, Safari, and Opera
                  },
                  scrollbarWidth: "none", // for Firefox
                  msOverflowStyle: "none" // for IE 10+
                }}
                gap={1}
              >
                {employee.tags.map((tag, i) => {
                  return (
                    <HnChip key={i} variant="outlined" size="sm">
                      {tag.name}
                    </HnChip>
                  );
                })}
              </Box>
            )}
          </Box>
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
      meta: {
        filterSelectOptions: tagCategories.data?.items
          .map((tagCategory) => {
            return tagCategory.tags.map((tag) => {
              return {
                option: tag.name,
                value: tag.name,
                category: tagCategory.name
              };
            });
          })
          .flat()
      },
      filterFn: (row: any, columnId, filterValues: string) => {
        const tagNames = (row.getValue(columnId) as Employee).tags.map(
          (tag) => tag.name
        );
        return (
          tagNames.filter((tagName) => filterValues.includes(tagName)).length >
          0
        );
      }
    },
    {
      accessorFn: (row) => {
        const employeeAccounts =
          accounts.data?.items.filter((x) => x.employeeId === row.id) ?? [];
        if (!employeeAccounts?.length) return 0;

        const availableBalance = employeeAccounts.reduce(
          (accountSum, account) =>
            accountSum + (account.availableBalance?.amount ?? 0),
          0
        );

        const startingBalance = employeeAccounts.reduce(
          (balanceSum, account) =>
            balanceSum + (account?.startingBalance.amount ?? 0),
          0
        );

        const percentageSpent =
          ((startingBalance - availableBalance) / startingBalance) * 100;
        return percentageSpent;
      },
      header: "Spend",
      cell: (cell: any) => {
        const size = 35,
          thickness = 10,
          value = cell.getValue(),
          secColor = theme.palette.grey[300];

        return (
          <Box display="flex">
            <CircularProgress
              variant="determinate"
              value={value}
              size={size}
              thickness={thickness}
              color="primary" // Set the color for the filled part
              sx={{
                borderRadius: "50%",
                boxShadow: `inset 0 0 0 ${
                  (thickness / 44) * size
                }px ${secColor}`,
                marginX: "auto"
              }}
            />
          </Box>
        );
      },
      enableColumnFilter: false
    },
    {
      accessorFn: (row) => {
        const employeeAccounts =
          accounts.data?.items.filter((x) => x.employeeId === row.id) ?? [];
        return employeeAccounts.map((a) => {
          const accountPlan = plans.data?.items.find((p) => p.id === a.planId);
          return accountPlan?.name;
        });
      },
      header: "Plans",
      cell: (cell) => {
        const assignedPlanNames = cell.getValue() as string[];
        return (
          <Box display="flex" gap={1}>
            {assignedPlanNames.map((assignedPlanName, i) => {
              return (
                <HnChip key={i} variant="filled" size="sm">
                  {assignedPlanName}
                </HnChip>
              );
            })}
          </Box>
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
      meta: {
        filterSelectOptions: plans.data?.items.map((plan) => {
          return {
            option: plan.name,
            value: plan.name
          };
        })
      },
      filterFn: (row: any, columnId, filterValues: string) => {
        const planNames = row.getValue(columnId) as string[];
        return (
          planNames.filter((planName) => filterValues.includes(planName))
            .length > 0
        );
      }
    },
    {
      accessorFn: (row) =>
        row.cards.find((card) => card.status !== CardStatus.Removed)?.status ??
        row.cards[0]?.status,
      header: "Card",
      cell: (cell) => {
        switch (cell.getValue() as CardStatus) {
          case CardStatus.Active:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title={CardStatus.Active} placement="top">
                  <CreditCard color={theme.palette.primary.main} />
                </LightTooltip>
              </Box>
            );
          case CardStatus.Created:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title={CardStatus.Created} placement="top">
                  <CreditCard color={theme.palette.grey[500]} />
                </LightTooltip>
              </Box>
            );
          case CardStatus.Issued:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title={CardStatus.Issued} placement="top">
                  <CreditCard color={theme.palette.grey[500]} />
                </LightTooltip>
              </Box>
            );
          case CardStatus.Inactive:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title={CardStatus.Inactive} placement="top">
                  <CreditCard color={theme.palette.grey[500]} />
                </LightTooltip>
              </Box>
            );
          case CardStatus.Removed:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title={CardStatus.Removed} placement="top">
                  <CreditCard color={theme.palette.error.main} />
                </LightTooltip>
              </Box>
            );
          case CardStatus.None:
          default:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title={CardStatus.None} placement="top">
                  <Typography>{"-"}</Typography>
                </LightTooltip>
              </Box>
            );
        }
      },
      enableSorting: false,
      enableGlobalFilter: false,
      meta: {
        filterSelectOptions: Object.keys(CardStatus).map((statusKey) => ({
          option: statusKey,
          value:
            statusKey === "None"
              ? "-"
              : CardStatus[statusKey as keyof typeof CardStatus] // Allows matching of "None" to "-"
        }))
      },
      filterFn: (row, columnId, filterValues: string) => {
        return filterValues.includes(row.getValue(columnId));
      }
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (cell) => {
        const status = cell.getValue() as EmployeeStatus;
        switch (status) {
          case EmployeeStatus.Active:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title={EmployeeStatus.Active} placement="top">
                  <CheckCircle color={theme.palette.primary.main} />
                </LightTooltip>
              </Box>
            );
          case EmployeeStatus.Created:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title={EmployeeStatus.Created} placement="top">
                  <Circle color={theme.palette.primary.dark} />
                </LightTooltip>
              </Box>
            );
          case EmployeeStatus.Inactive:
          default:
            return (
              <Box display="flex" justifyContent="center">
                <LightTooltip title={EmployeeStatus.Inactive} placement="top">
                  <MinusCircle color={theme.palette.error.main} />
                </LightTooltip>
              </Box>
            );
        }
      },
      meta: {
        filterSelectOptions: Object.keys(EmployeeStatus)
          .filter(
            (statusKey) =>
              EmployeeStatus[statusKey as keyof typeof EmployeeStatus] !==
              EmployeeStatus.Deleted
          )
          .map((statusKey) => ({
            option: statusKey,
            value: EmployeeStatus[statusKey as keyof typeof EmployeeStatus]
          }))
      },
      filterFn: (row, columnId, filterValues: string[]) => {
        return filterValues.includes(row.getValue(columnId));
      },
      enableSorting: false,
      id: "Status"
    },
    {
      id: "Edit",
      cell: ({ row }) => (
        <IconButton onClick={() => handleEditRow(row.original)}>
          <Settings />
        </IconButton>
      ),
      enableSorting: false
    }
  ];

  // Prioritise "Departments" tag for useability
  // (instead of default sorting which would be in order of last modified)
  function compareTags(tag: Tag) {
    const tagCategory = tagCategories.data?.items.find(
      (tc) => tc.id === tag.tagCategoryId
    );
    if (tagCategory?.name === "Departments") {
      return -1;
    }
    return 1;
  }

  function handleEditRow(employee: Employee) {
    setEditingEmployee(employee);
  }

  function handleStopEditRow() {
    setEditingEmployee(undefined);
  }

  if (employees.isLoading || employees.isIdle) return <LoadingSpinner />;

  if (employees.error || !employees.data)
    return <p>Something went wrong, please try again later</p>;

  const getHeaderActions = () => {
    return (
      <>
        <Button
          variant="contained"
          onClick={() => setAddUserModalOpen(true)}
          endIcon={<UserPlus size={16} />}
          size="medium"
          disabled={
            membership.data?.features?.maxEmployeeCount
              ? employees.data.totalItems >=
                membership.data.features.maxEmployeeCount
              : false
          }
        >
          Add user
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate(`/employees/import`)}
          endIcon={<Upload size={16} />}
          size="medium"
          disabled={
            membership.data?.features?.maxEmployeeCount
              ? employees.data.totalItems >=
                membership.data.features.maxEmployeeCount
              : false
          }
        >
          Import Users
        </Button>
      </>
    );
  };

  const getItemActions = () => {
    return (
      <>
        <Button
          variant="contained"
          onClick={() => setAssignTagsModalOpen(true)}
          endIcon={<TagIcon size={16} />}
          size="small"
        >
          Apply Tags
        </Button>
        <Button
          variant="contained"
          onClick={() => setAssignToPlanModalOpen(true)}
          endIcon={<Sliders size={16} />}
          size="small"
        >
          Assign to plan
        </Button>
        <Button
          variant="contained"
          onClick={() => setUnAssignToPlanModalOpen(true)}
          endIcon={<Sliders size={16} />}
          size="small"
        >
          Un-Assign Plan
        </Button>
        <Fragment>
          <Button
            variant="contained"
            onClick={() => setSendMessageModalOpen(true)}
            endIcon={<Send size={16} />}
            size="small"
          >
            Send message
          </Button>
          <Button
            variant="contained"
            onClick={() => setIssueCardModalOpen(true)}
            endIcon={<CreditCard size={16} />}
            size="small"
          >
            Issue cards
          </Button>
          <Button
            variant="contained"
            onClick={() => handleDownloadCsv(selectedEmployees)}
            endIcon={<Download size={16} />}
            size="small"
          >
            Export to CSV
          </Button>
        </Fragment>
      </>
    );
  };

  return (
    <>
      <Table<Employee>
        key={`MaterialTable-${tableResetKey}`}
        data={employees.data.items}
        totalItems={employees.data.totalItems}
        columns={columns}
        defaultSorting={[{ id: "Name", desc: false }]}
        onSelectionChanges={setSelectedEmployees}
        headerActions={getHeaderActions()}
        itemActions={getItemActions()}
      />
      <AddUser
        open={addUserModalOpen}
        handleClose={() => setAddUserModalOpen(false)}
      />
      {assignTagsModalOpen && (
        <ApplyTags
          open={true}
          handleComplete={() => handleResetTableSelection()}
          handleClose={() => setAssignTagsModalOpen(false)}
          selectedEmployees={selectedEmployees}
        />
      )}
      {assignToPlanModalOpen && (
        <AssignPlans
          open={true}
          handleComplete={() => handleResetTableSelection()}
          handleClose={() => setAssignToPlanModalOpen(false)}
          selectedEmployees={selectedEmployees}
        />
      )}
      {unAssignToPlanModalOpen && (
        <UnAssignPlans
          open={true}
          handleComplete={() => handleResetTableSelection()}
          handleClose={() => setUnAssignToPlanModalOpen(false)}
          selectedEmployees={selectedEmployees}
        />
      )}
      {sendMessageModalOpen && (
        <SendMessage
          open={true}
          handleComplete={() => handleResetTableSelection()}
          handleClose={() => setSendMessageModalOpen(false)}
          selectedEmployees={selectedEmployees}
        />
      )}
      {issueCardModalOpen && (
        <IssueCards
          open={true}
          handleComplete={() => handleResetTableSelection()}
          handleClose={() => setIssueCardModalOpen(false)}
          selectedEmployees={selectedEmployees}
        />
      )}
      {editingEmployee && (
        <EditUser
          open={true}
          handleClose={handleStopEditRow}
          employee={editingEmployee}
        />
      )}
    </>
  );
};

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import HealthNowCard from "../../assets/cards/HealthNowCardFront.png";
import {
  CardStatus,
  CardStatusResponse,
  Card as CardType,
  Employee,
  EmployeeAccount
} from "../../models";
import { fetchData } from "../../utils/fetchData";
import { formatCurrency } from "../../utils/formatters";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { AllTransactions } from "../modals/AllTransactions";
import { LoadingSpinner } from "./LoadingSpinner";

type CardProps = {
  cardId?: string;
  employee: Employee;
};

export const Card = ({ cardId, employee }: CardProps) => {
  const { config } = useConfig();
  const { employerId, employees, accounts } = useData();
  const { getToken, getBooleanFlag } = useKindeAuth();
  const [error, setError] = useState("");
  const [cardReIssued, setCardReIssued] = useState(false);
  const [isBlockCardModalOpen, setIsBlockCardModalOpen] = useState(false);
  const [isReIssueCardModalOpen, setIsReIssueCardModalOpen] = useState(false);
  const [isAllTransactionsModalOpen, setIsAllTransactionModalOpen] =
    useState(false);
  const [employeeAccounts, setEmployeeAccounts] = useState<EmployeeAccount[]>(
    []
  );

  const blockCardMutation = useMutation({
    mutationFn: async () => {
      const blockCard = cardStatus.data?.status === CardStatus.Active;

      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/cards/${cardId}/block`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            blockOption: blockCard ? "BLOCK" : "UNBLOCK"
          })
        }
      );

      if (!response.ok)
        throw new Error(
          `There was a problem ${
            blockCard ? "blocking" : "unblocking"
          } the card`
        );
    },
    onSuccess: () => {
      toast.success(
        `Card successfully ${
          cardStatus.data?.status === CardStatus.Active
            ? "blocked"
            : "unblocked"
        }`
      );

      if (cardStatus.data?.status) {
        cardStatus.data.status = CardStatus.Active; // Manually update to the expected state (Avoid need for a loading animation for card)
      }

      cardStatus.refetch();
      cardDetails.refetch();
      employees.refetch();
      setIsBlockCardModalOpen(false);
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const reIssueCardMutation = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/cards/${cardId}/replacement`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            reasonCode: "L"
          })
        }
      );
      if (!response.ok)
        throw new Error(`There was a problem re-issuing a card for this user.`);
    },
    onSuccess: () => {
      setCardReIssued(true);
      toast.success("Card successfully re-issued.");
      cardStatus.refetch();
      cardDetails.refetch();
      employees.refetch();
      setIsReIssueCardModalOpen(false);
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const cardDetails = useQuery<CardType>(
    `${config?.API_URL}/employers/${employerId}/cards/${cardId}`,
    () =>
      fetchData(
        `${config?.API_URL}/employers/${employerId}/cards/${cardId}`,
        getToken
      ),
    {
      enabled: !!cardId
    }
  );

  const cardStatus = useQuery<CardStatusResponse>(
    `${config?.API_URL}/employers/${employerId}/cards/${cardId}/status`,
    () =>
      fetchData(
        `${config?.API_URL}/employers/${employerId}/cards/${cardId}/status`,
        getToken
      ),
    {
      enabled: !!cardId
    }
  );

  // Refetch card status / details on employee.cards changing
  useEffect(() => {
    if (!cardId) {
      return;
    }
    cardDetails.refetch();
    cardStatus.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.cards]);

  useEffect(() => {
    if (!accounts.isLoading) {
      setEmployeeAccounts(
        accounts.data?.items.filter((x) => x.employeeId === employee.id) ?? []
      );
    }
  }, [accounts, employee]);

  // Clear error on modal close/open
  useEffect(() => {
    setError("");
  }, [
    isBlockCardModalOpen,
    isReIssueCardModalOpen,
    isAllTransactionsModalOpen
  ]);

  if (
    cardId &&
    (cardDetails.isIdle ||
      cardDetails.isLoading ||
      cardStatus.isIdle ||
      cardStatus.isLoading)
  ) {
    return (
      <>
        <Grid item xs={12}>
          <LoadingSpinner />
        </Grid>
      </>
    );
  }

  if (cardReIssued) {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h6" mb="16px">
            Card
          </Typography>
          <Typography>Card re-issued</Typography>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" mb="16px">
          Card
        </Typography>
        {cardId && cardStatus.data ? (
          <>
            {cardStatus.data?.status === CardStatus.Removed ? (
              <>
                <Typography>Card is removed</Typography>
                {cardStatus.data?.isReIssuable && (
                  <Button
                    style={{ marginTop: 10 }}
                    variant="outlined"
                    color="error"
                    fullWidth
                    onClick={() => setIsReIssueCardModalOpen(true)}
                  >
                    Re-Issue Card
                  </Button>
                )}
              </>
            ) : (
              <>
                <Box maxWidth={"300px"} mx="auto" mb={1} position="relative">
                  <img
                    src={
                      cardDetails.data?.cardDesignId &&
                      config?.PUBLIC_BUCKET_URL
                        ? `${config.PUBLIC_BUCKET_URL}/card-designs/${cardDetails.data.cardDesignId}/normal.png`
                        : HealthNowCard
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = HealthNowCard;
                    }}
                    alt="HealthNow card"
                    style={{ width: "100%" }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "38px",
                      left: "15px"
                    }}
                  >
                    <Typography sx={{ fontWeight: 500 }}>
                      {cardDetails.data?.maskedCardNumber
                        ? cardDetails.data.maskedCardNumber
                            ?.match(/.{1,4}/g)
                            ?.join(" ")
                        : ""}
                    </Typography>
                  </Box>
                </Box>
                {(cardStatus.data?.status === CardStatus.Inactive ||
                  cardStatus.data?.status === CardStatus.Active) && (
                  <Button
                    variant="outlined"
                    color={
                      cardStatus.data?.status === CardStatus.Inactive
                        ? "error"
                        : "secondary"
                    }
                    fullWidth
                    onClick={() => setIsBlockCardModalOpen(true)}
                  >
                    {cardStatus.data?.status === CardStatus.Active
                      ? "Block card"
                      : "Unblock Card"}
                  </Button>
                )}
                {cardStatus.data?.isReIssuable && (
                  <Button
                    style={{ marginTop: 10 }}
                    variant="outlined"
                    color="error"
                    fullWidth
                    onClick={() => setIsReIssueCardModalOpen(true)}
                  >
                    Re-Issue Card
                  </Button>
                )}
                <List>
                  <ListItem disablePadding>
                    <ListItemText primary="Expires on" />
                    <Typography>
                      {cardDetails.data?.expiry.slice(2, 4)}/
                      {cardDetails.data?.expiry.slice(0, 2)}
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText primary="Available balance" />
                    <Typography>
                      {/* maybe need to check for account status */}
                      {formatCurrency(
                        employeeAccounts.reduce(
                          (sum, account) =>
                            sum + account.availableBalance.amount,
                          0
                        )
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <Box display="flex" justifyContent="flex-end">
                  {getBooleanFlag("customer_view_transactions") && (
                    <Button
                      color="secondary"
                      onClick={() => setIsAllTransactionModalOpen(true)}
                    >
                      View transactions
                    </Button>
                  )}
                </Box>
              </>
            )}
          </>
        ) : (
          "User does not have a card"
        )}
      </Grid>

      <Dialog
        open={isBlockCardModalOpen}
        onClose={() => setIsBlockCardModalOpen(false)}
      >
        <DialogTitle>
          {cardStatus.data?.status === CardStatus.Active
            ? "Block card"
            : "Unblock Card"}
        </DialogTitle>
        <DialogContent>
          {error && <Typography color="error">{error}</Typography>}
          <DialogContentText>
            {cardStatus.data?.status === CardStatus.Active
              ? `Are you sure you want to block ${employee.firstName} ${employee.lastName}'s card? They will be unable to use it.`
              : `Are you sure you want to unblock ${employee.firstName} ${employee.lastName}'s card?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsBlockCardModalOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={blockCardMutation.isLoading}
            onClick={() => blockCardMutation.mutate()}
            autoFocus
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {employee.cards.length > 0 && (
        <Dialog
          open={isReIssueCardModalOpen}
          onClose={() => setIsReIssueCardModalOpen(false)}
        >
          <DialogTitle>Re-Issue Card</DialogTitle>
          <DialogContent>
            {error && <Typography color="error">{error}</Typography>}
            <DialogContentText>
              {employee.cards.find((c) => c.id === cardId)?.status ===
              CardStatus.Removed
                ? `Are you sure you want to re-issue a card for ${employee.firstName} ${employee.lastName}?`
                : `Are you sure you want to re-issue ${employee.firstName} ${employee.lastName}'s card? This will cancel their existing card.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsReIssueCardModalOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={reIssueCardMutation.isLoading}
              onClick={() => reIssueCardMutation.mutate()}
              autoFocus
              variant="contained"
            >
              Re-Issue Card
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}

      {isAllTransactionsModalOpen && (
        <AllTransactions
          open={true}
          handleClose={() => setIsAllTransactionModalOpen(false)}
          employeeId={employee.id}
        />
      )}
    </>
  );
};

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import { FormEvent, useState } from "react";
import { PlanReference } from "../../models";
import { PlanSelection } from "./PlanSelection";

type AssignToPlanProps = {
  open: boolean;
  handleClose: () => void;
  selectedEmployeesWithoutPlan: number;
  onSelectPlans: (selectedPlans: PlanReference[]) => void;
  isLoading: boolean;
  error: string;
};

const DEFAULT_VALUE = "-1";

export const SelectPlans = ({
  open,
  handleClose,
  selectedEmployeesWithoutPlan,
  onSelectPlans,
  isLoading,
  error
}: AssignToPlanProps) => {
  const [selectedPlans, setSelectedPlans] = useState<PlanReference[]>([]);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    onSelectPlans(selectedPlans);
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>
            Assign {selectedEmployeesWithoutPlan} user
            {selectedEmployeesWithoutPlan > 1 ? "s" : ""} to plan(s)
          </DialogTitle>
        </Box>
        <DialogContent>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            <Grid item>
              <Typography>General instructions on assigning plans</Typography>
              <Typography color="gray">
                Only users without a plan can have their plans set here. If you
                want to add a new plan to a user with an active plan please edit
                that user directly.
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <PlanSelection
                selectedPlans={selectedPlans}
                setSelectedPlans={setSelectedPlans}
                DEFAULT_VALUE={DEFAULT_VALUE}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
            disabled={selectedEmployeesWithoutPlan === 0}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

import React from 'react';
import { Box, Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { BackButton } from '../shared/BackButton';
import EmployeeInput from './EmployeeInput';
import { InvitedEmployee, Plan } from "../../models";

export const Step3InviteEmployees: React.FC<{
  next: () => void;
  back: () => void;
  invitedEmployees: InvitedEmployee[];
  plans: Plan[];
  onEmployeesChange: (emails: InvitedEmployee[]) => void;
}> = ({ next, back, invitedEmployees, plans, onEmployeesChange }) => {
  const theme = useTheme();

  return (
    <Grid container item>
      <Grid item xs={8}>
        <Typography variant="h4" fontSize={32} fontWeight={700}>
          Invite your staff
        </Typography>
        <Box>
          <Typography textAlign="center" mt={8} mb={6} color={theme.palette.grey[500]}>
            You can add all your staff at another time in the dashboard
          </Typography>
          <EmployeeInput
            onEmployeesChange={onEmployeesChange}
            invitedEmployees={invitedEmployees}
            plans={plans} />
          <Divider />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 5 }}>
          <BackButton onClick={back} />
          <Button variant="contained" onClick={next} disabled={invitedEmployees.length === 0}>
            Continue
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
  Route,
  Routes as Router,
  useLocation
} from "react-router-dom";
import Zendesk from "react-zendesk";
import { LoadingSpinner } from "./components/shared/LoadingSpinner";
import { NotAuthorized } from "./pages/not-authorized/NotAuthorized";
import { Signup } from "./pages/signup/Signup";
import { AuthenticatedRoutes } from "./Routes";
import { useConfig } from "./utils/useConfig";
import { useFeatures } from "./utils/useData";

function App() {
  const { config } = useConfig();
  const location = useLocation();
  const { isLoading: isFeaturesLoading, data: features } = useFeatures();
  const { isAuthenticated, isLoading, error, login, getPermission } = useKindeAuth();

  const resolveRoutes = () => {
    if (!isAuthenticated
      && features?.find((f) => f.name === "self-service-onboarding" && f.isEnabled)
      && config?.GOOGLE_RECAPTCHA_SITE_KEY) {
      return (
        // Self-Service Signup - Requires Feature enabled + ReCaptcha API key
        <GoogleReCaptchaProvider reCaptchaKey={config?.GOOGLE_RECAPTCHA_SITE_KEY ?? ""}>
          <Router>
            <Route path="/signup" Component={Signup} />
          </Router>
        </GoogleReCaptchaProvider>
      );
    } else if (getPermission("employer_access").isGranted) {
      // Regular authenticated routes
      return <AuthenticatedRoutes />;
    } else {
      return <NotAuthorized />;
    }
  };

  useEffect(() => {
    // Don't force re-dir to login if on signup route
    if (isAuthenticated || isLoading || location.pathname === "/signup") {
      return;
    }
    login &&
    login({
      app_state: {
        redirectTo: window.location.pathname
      }
    });
  }, [isAuthenticated, isLoading, location.pathname, login]);

  if (isLoading || isFeaturesLoading) return <LoadingSpinner />;

  if (error) return <p>{error}</p>;

  return (
    <>
      {config?.ZENDESK_KEY && (
        <Zendesk defer zendeskKey={config?.ZENDESK_KEY} />
      )}
      {resolveRoutes()}
    </>
  );
}

export default App;

import { Box, Fade, Grid, Skeleton, Typography } from "@mui/material";
import { useMemo } from "react";
import { TransactionStatus, TransactionType } from "../../models";
import { generateColorGradient } from "../../utils/generateColorGradient";
import { useData } from "../../utils/useData";
import MainCard from "../shared/MainCard";

export const Totals = () => {
  const { employees, ledgers, transactions, accounts } = useData();

  const colors = generateColorGradient(5);

  const totals = useMemo(() => {
    const totalContributionValue = accounts.data?.items.reduce(
      (sum, account) => {
        return sum + account.totalContributionAmount.amount;
      },
      0
    );

    const totalActualBalance = accounts.data?.items.reduce((sum, account) => {
      return sum + account.actualBalance?.amount ?? 0;
    }, 0);

    // Due to adjustments, this could result in values that don't "Add Up" by minor value(s) in an employers eyes
    const totalEmployeeSpend = transactions.data?.items
      .filter(
        (t) =>
          (t.status === TransactionStatus.APPROVED
            || t.status === TransactionStatus.PENDING)
          && t.type === TransactionType.PURCHASE
      )
      .reduce(
        (sum, transaction) =>
          sum + (transaction.transactionAmount?.amount ?? 0),
        0
      );

    // const totalEmployeeSpend =
    //   (totalContributionValue ?? 0) - (totalActualBalance ?? 0);

    return {
      totalContributionValue,
      totalEmployeeSpend,
      totalActualBalance
    };
  }, [employees.data?.items]);

  return (
    <>
      <Fade in={true}>
        <Grid item xs={12}>
          {!employees.isFetched ||
          !ledgers.isFetched ||
          ledgers.isLoading ||
          employees.isLoading ? (
            <Box display="flex" gap={2} width="100%">
              <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height={105}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height={105}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height={105}
              />
            </Box>
          ) : (
            <Box display="flex" gap={2}>
              {/* @ts-ignore-next-line */}
              <MainCard
                content={false}
                sx={{
                  width: "100%",
                  backgroundColor: colors[2],
                  textAlign: "right",
                  padding: 2
                }}
              >
                <Typography color="white">Total Contribution Value</Typography>
                <Typography color="white" variant="h5" py={1}>
                  {totals.totalContributionValue?.toLocaleString("en-NZ", {
                    style: "currency",
                    currency: "NZD",
                    maximumFractionDigits: 0
                  })}
                </Typography>
              </MainCard>
              {/* @ts-ignore-next-line */}
              <MainCard
                content={false}
                sx={{
                  width: "100%",
                  backgroundColor: colors[1],
                  textAlign: "right",
                  padding: 2
                }}
              >
                <Typography color="white">Total Employee Spend</Typography>
                <Typography color="white" variant="h5" py={1}>
                  {totals.totalEmployeeSpend?.toLocaleString("en-NZ", {
                    style: "currency",
                    currency: "NZD",
                    maximumFractionDigits: 0
                  })}
                </Typography>
              </MainCard>
              {/* @ts-ignore-next-line */}
              <MainCard
                content={false}
                sx={{
                  width: "100%",
                  backgroundColor: colors[0],
                  textAlign: "right",
                  padding: 2
                }}
              >
                <Typography color="white">Total Available Balance</Typography>
                <Typography color="white" variant="h5" py={1}>
                  {totals.totalActualBalance?.toLocaleString("en-NZ", {
                    style: "currency",
                    currency: "NZD",
                    maximumFractionDigits: 0
                  })}
                </Typography>
              </MainCard>
            </Box>
          )}
        </Grid>
      </Fade>
    </>
  );
};

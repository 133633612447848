import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Step0Welcome } from "../../components/onboarding/0_welcome";
import { Step3InviteEmployees } from "../../components/onboarding/3_inviteEmployees";
import { Step4SetupFloat } from "../../components/onboarding/4_setupFloat";
import { Step5ReviewAndComplete } from "../../components/onboarding/5_reviewAndComplete";
import { EmployeeAccountSetupSteps } from "../../components/onboarding/EmployeeAccountSetupSteps";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { OnboardingLayout } from "../../layouts/OnboardingLayout";
import { OnboardingProgress } from "../../models/onboardingProgress";
import { fetchData } from "../../utils/fetchData";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { InvitedEmployee, Plan } from "../../models";

export const Onboarding = () => {
  const navigate = useNavigate();
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const { employer, employees, userInfoData, plans, invoices } = useData();
  const employee = employees.data?.items.find((e) => e.id === userInfoData?.employeeId);

  // We have steps (Welcome, Create Employee Accounts, Add Team, Payment), but we then have sub-steps within these
  const [step, setStep] = useState(0);
  const [subStep, setSubStep] = useState(0);
  const [invitedEmployees, setInvitedEmployees] = useState<InvitedEmployee[]>([]);

  const [existingPlan, setExistingPlan] = useState<Plan | null>(
    plans.data?.items[0] ?? null
  );

  const getOnboardingProgress = useQuery<OnboardingProgress>(
    `${config?.API_URL}/employers/${employer.data?.id}/onboarding-progress`,
    () =>
      fetchData(
        `${config?.API_URL}/employers/${employer.data?.id}/onboarding-progress`,
        getToken
      ),
    {
      enabled: !!employer.data?.id
    }
  );

  const updateOnboardingProgress = useMutation(
    async ({ step, subStep, isCompleted, invitedEmployees }: any) => {

      await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}/onboarding-progress`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ step, subStep, isCompleted, invitedEmployees })
        }
      );
    },
    {
      onError: (error: Error) => {
        console.error(error.message);
      }
    }
  );

  useEffect(() => {
    setExistingPlan(plans.data?.items[0] ?? null);
  }, [plans]);

  useEffect(() => {
    if (!getOnboardingProgress.isLoading && getOnboardingProgress.data && step === 0 && subStep === 0) {
      setStep(getOnboardingProgress.data.step);
      setSubStep(getOnboardingProgress.data.subStep);
      setInvitedEmployees(getOnboardingProgress.data?.invitedEmployees ?? []);
      // @ts-ignore
      if (getOnboardingProgress.data.isCompleted && step !== 4) {
        navigate('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOnboardingProgress.data]);

  // Update the onboarding progress on step update
  useEffect(() => {
    if (step !== 0 && subStep !== 0)
      // Stop the step being reset to 0
      updateOnboardingProgress.mutate({
        step,
        subStep,
        invitedEmployees
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, subStep]);

  if (employer.isLoading
    || employees.isLoading
    || plans.isLoading
    || getOnboardingProgress.isLoading
    || invoices.isLoading) {
    return <LoadingSpinner />;
  }

  const calculateMinimumFloatAmount = (employees: InvitedEmployee[], plans: Plan[]) => {
    let totalAmount = 0;
    employees.forEach(employee => {
      employee.plans.forEach(planRef => {
        const plan = plans.find(p => p.id === planRef.planId);
        const tier = plan?.tiers.find(t => t.id === planRef.tierId);
        if (tier) {
          totalAmount += tier.amount.amount;
        }
      });
    });
    return totalAmount * 0.1;
  };

  const resolveStep = () => {
    switch (step) {
      case 0: {
        return (
          <Step0Welcome employer={employer.data} next={() => setStep(1)} />
        );
      }
      case 1: {
        return (
          <EmployeeAccountSetupSteps
            step={subStep}
            setStep={setSubStep}
            existingPlan={existingPlan}
            refetchPlan={plans.refetch}
            setMasterStep={setStep}
          />
        );
      }
      case 2: {
        return (
          <Step3InviteEmployees
            invitedEmployees={invitedEmployees}
            onEmployeesChange={(employees) => {
              setInvitedEmployees(employees ?? []);
            }}
            plans={plans.data!.items}
            next={() => setStep(3)}
            back={() => {
              setStep(1);
              setSubStep(6);
            }}
          />
        );
      }
      case 3: {
        return (
          <Step4SetupFloat
            employer={employer.data}
            next={() => {
              updateOnboardingProgress.mutate({
                step: 4,
                subStep,
                invitedEmployees,
                isCompleted: true
              });
              setStep(4);
            }}
            back={() => setStep(2)}
            invoices={invoices.data!.items}
            minimumFloat={calculateMinimumFloatAmount(invitedEmployees, plans.data!.items)}
          />
        );
      }
      case 4: {
        return (
          <Step5ReviewAndComplete
            loading={updateOnboardingProgress.isLoading}
            onFinish={async () => {
              await getOnboardingProgress.refetch();
              navigate('/');
            }}
          />
        );
      }
    }
  };

  if (employer.isLoading) return <LoadingSpinner />;

  if (!employer || !employee) {
    navigate("/");
    return null;
  }

  return (
    <OnboardingLayout
      employer={employer.data}
      employee={employee}
      step={step}
      subStep={subStep}
      totalSteps={4}
    >
      {resolveStep()}
    </OnboardingLayout>
  );
};

import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { FC } from "react";

export const Step5ReviewAndComplete: FC<{
  loading: boolean;
  onFinish: () => void;
}> = ({ loading, onFinish }) => {
  return (
    <Grid container item>
      <Grid item xs={8}>
        <Typography variant="h4" fontSize={32} fontWeight={700}>
          Great, you're all set!
        </Typography>
        <Typography variant="subtitle1" my={1}>
          Email invitations have been sent to your staff to enjoy the Employee
          Account
        </Typography>

        <LoadingButton
          loading={loading}
          onClick={onFinish}
          variant="contained"
          sx={{ mt: 5 }}
        >
          Finish
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

import { Box, Button, Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { PlanType } from "../../../models";
import { CreatePlanInput } from "../../plans/AddPlan";
import { BackButton } from "../../shared/BackButton";
import { AccountRadioBox } from "../AccountRadioBox";

export const Step1Type: FC<{
  formik: FormikProps<CreatePlanInput>;
  next: () => void;
  back: () => void;
}> = ({ formik, next, back }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        Will the account be ongoing or one off?
      </Typography>
      <Box display="flex" width="100%" gap={3} my={4}>
        <AccountRadioBox
          title={"Ongoing"}
          content={"Renews at your chosen period"}
          isSelected={formik.values.planType === PlanType.ONGOING}
          onClick={() => formik.setFieldValue("planType", PlanType.ONGOING)}
        />
        <AccountRadioBox
          title={"One-off"}
          content={"A one off contribution with a set expiration"}
          isSelected={formik.values.planType === PlanType.ONE_OFF}
          onClick={() => formik.setFieldValue("planType", PlanType.ONE_OFF)}
        />
      </Box>
      <>
        <Typography variant="h6" fontWeight={500}>
          {formik.values.planType === PlanType.ONGOING
            ? "Contribution Renewal Period"
            : "Contribution Expiry"}
        </Typography>
        <Box display="flex" width="100%" gap={3} my={2}>
          <AccountRadioBox
            title={
              formik.values.planType === PlanType.ONGOING
                ? "Monthly"
                : "1 Month"
            }
            content={
              formik.values.planType === PlanType.ONGOING
                ? "Renews every month"
                : "Expires in 1 Month"
            }
            isSelected={formik.values.renewalOptions.periodInMonths === 1}
            onClick={() =>
              formik.setFieldValue("renewalOptions.periodInMonths", 1)
            }
          />
          <AccountRadioBox
            title={
              formik.values.planType === PlanType.ONGOING
                ? "Bi-Annual"
                : "6 Months"
            }
            content={
              formik.values.planType === PlanType.ONGOING
                ? "Renews every 6 months"
                : "Expires in 6 months"
            }
            isSelected={formik.values.renewalOptions.periodInMonths === 6}
            onClick={() =>
              formik.setFieldValue("renewalOptions.periodInMonths", 6)
            }
          />
          <AccountRadioBox
            title={
              formik.values.planType === PlanType.ONGOING
                ? "Annual"
                : "12 Months"
            }
            content={
              formik.values.planType === PlanType.ONGOING
                ? "Renews every 12 months"
                : "Expires in 12 months"
            }
            isSelected={formik.values.renewalOptions.periodInMonths === 12}
            onClick={() =>
              formik.setFieldValue("renewalOptions.periodInMonths", 12)
            }
          />
        </Box>
      </>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 10 }}
      >
        <BackButton onClick={back} />
        <Button variant="contained" onClick={next}>
          Continue
        </Button>
      </Box>
    </Grid>
  );
};

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { FormEvent, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { RichTextEditor } from "../messages/RichTextEditor";
import { Employee } from "../../models";

type SendMessageProps = {
  open: boolean;
  handleComplete: () => void;
  handleClose: () => void;
  selectedEmployees: Employee[];
};

export const SendMessage = ({
  open,
  handleComplete,
  handleClose,
  selectedEmployees
}: SendMessageProps) => {
  const { config } = useConfig();
  const { employees, messageTemplates, employer } = useData();
  const { getToken } = useKindeAuth();

  const [selectedMessageTemplateId, setSelectedMessageTemplateId] = useState(
    messageTemplates.data?.find((x) => x.type === "INVITATION")?.id ?? ""
  );
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const selectedMessageTemplate = messageTemplates.data?.find(
    (messageTemplate) => messageTemplate.id === selectedMessageTemplateId
  );

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await fetch(`${config?.API_URL}/messages`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          templateId: selectedMessageTemplate?.id,
          parameters: {
            employer: employer.data?.name,
            message
          },
          recipients: selectedEmployees.map((employee) => ({
            employeeId: employee.id,
            email: employee.contact.email,
            firstName: employee.firstName,
            lastName: employee.lastName
          }))
        })
      });

      if (!response.ok) {
        throw new Error("There was a problem sending the message");
      }
    },
    onSuccess: () => {
      employees.refetch();
      toast.success("Message sent successfully");
      handleClose();
      handleComplete();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    mutation.mutate();
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>
            Send message to {selectedEmployees.length} user
            {selectedEmployees.length > 1 ? "s" : ""}
          </DialogTitle>
        </Box>
        <DialogContent>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            {selectedMessageTemplate?.description && (
              <Grid item xs={12}>
                <Typography>{selectedMessageTemplate?.description}</Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <InputLabel id="messageType">Message type</InputLabel>
              <Select
                value={selectedMessageTemplateId}
                onChange={(e) => setSelectedMessageTemplateId(e.target.value)}
                labelId="messageType"
                size="small"
                fullWidth
              >
                {messageTemplates.data?.map((messageTemplate) => (
                  <MenuItem key={messageTemplate.id} value={messageTemplate.id}>
                    {messageTemplate.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {selectedMessageTemplate?.parameters.includes("message") && (
              <Grid item xs={12}>
                <RichTextEditor value={message} updateValue={setMessage} />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={mutation.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

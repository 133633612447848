import { Navigate, Route, Routes as Router } from "react-router-dom";
import { Account } from "./pages/account/Account";
import { Billing } from "./pages/billing/Billing";
import { FloatManagement } from "./pages/billing/FloatManagement";
import { InvoiceDetails } from "./pages/billing/InvoiceDetails";
import { EmployeeTags } from "./pages/employee-tags/EmployeeTags";
import { Employees } from "./pages/employees/Employees";
import { ImportEmployees } from "./pages/employees/ImportEmployees";
import { Home } from "./pages/home/Home";
import { Plans } from "./pages/plans/Plans";
import { Reporting } from "./pages/reporting/Reporting";
import { Onboarding } from "./pages/onboarding/Onboarding";
import { MainLayout } from "./layouts/MainLayout";
import { useEffect, useState } from "react";
import { useData, useFeatures } from "./utils/useData";
import { useQuery } from "react-query";
import { OnboardingProgress } from "./models/onboardingProgress";
import { useConfig } from "./utils/useConfig";
import { fetchData } from "./utils/fetchData";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { MembershipType } from "./models";

export const AuthenticatedRoutes = () => {
  const { config } = useConfig();
  const { employer, membership } = useData();
  const { getToken } = useKindeAuth();
  const { data: features } = useFeatures();
  const [isOnboardingComplete, setIsOnboardingComplete] = useState<boolean>(true);
  const [isSelfService, setIsSelfService] = useState<boolean>(false); // Comes from membership reference type

  const getOnboardingProgress = useQuery<OnboardingProgress>(
    `${config?.API_URL}/employers/${employer.data?.id}/onboarding-progress`,
    () =>
      fetchData(
        `${config?.API_URL}/employers/${employer.data?.id}/onboarding-progress`,
        getToken
      ),
    {
      enabled: !!employer.data?.id && !membership.isLoading && membership.data!.type === MembershipType.SELF_SERVICE
    }
  );

  useEffect(() => {
    if (!employer.isLoading
      && isSelfService
      && !getOnboardingProgress.isLoading
      && getOnboardingProgress.data) {
      setIsOnboardingComplete(getOnboardingProgress.data.isCompleted);
    }
  }, [employer, employer.data, isSelfService, getOnboardingProgress, getOnboardingProgress.data, getOnboardingProgress.isLoading]);

  useEffect(() => {
    if (!membership.isLoading && membership.data) {
      setIsSelfService(membership.data!.type === MembershipType.SELF_SERVICE);
    }
  }, [membership, membership.isLoading, membership.data]);

  if (!isOnboardingComplete
    && isSelfService
    && features?.find((f) => f.name === "self-service-onboarding" && f.isEnabled)) {
    // Self-Service Onboarding - Initial walkthrough for first time setup
    return (
      <Router>
        <Route path="/onboarding" Component={Onboarding} />
        <Route path="*" element={<Navigate to="/onboarding" replace />} />
      </Router>
    );
  }

  return (
    <MainLayout>
      <Router>
        <Route path="/" Component={Home} />
        <Route path="/employees" Component={Employees} />
        <Route path="/employees/import" Component={ImportEmployees} />
        <Route path="/reporting" Component={Reporting} />
        <Route path="/plans" Component={Plans} />
        <Route path="/billing" Component={Billing} />
        <Route path="/invoices/:invoiceId" element={<InvoiceDetails />} />
        <Route path="/float-management" Component={FloatManagement} />
        <Route path="/employee-tags" Component={EmployeeTags} />
        <Route path="/account" Component={Account} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Router>
    </MainLayout>
  );
};

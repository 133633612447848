import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Employee, PlanReference } from "../../models";
import { checkJobProgress } from "../../utils/checkJobProgress";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { SelectPlans } from "./SelectPlans";

type AssignToPlanProps = {
  open: boolean;
  handleComplete: () => void;
  handleClose: () => void;
  selectedEmployees: Employee[];
};

const DEFAULT_VALUE = "-1";

export const AssignPlans = ({
                              open,
                              handleComplete,
                              handleClose,
                              selectedEmployees
                            }: AssignToPlanProps) => {
  const { config } = useConfig();
  const { employerId, employees, accounts } = useData();
  const { getToken } = useKindeAuth();
  const [error, setError] = useState("");

  const selectedEmployeesWithoutPlan = selectedEmployees.filter(
    (employee) =>
      employee.accounts.length === 0 ||
      employee.accounts.every((account) => account.deletedAt)
  );

  const assignSynchronously = useMutation({
    mutationFn: async (selectedPlans: PlanReference[]) => {
      // Initialize a progress toast
      const totalEmployees = selectedEmployeesWithoutPlan.length;
      let completed = 0;
      let toastId = toast("Assigning Plans...", {
        progress: 0,
        autoClose: false,
        type: "info"
      });

      const payload = JSON.stringify(selectedPlans.filter((x) => x.tierId !== DEFAULT_VALUE));

      await Promise.all(
        selectedEmployeesWithoutPlan.map(async (employee) => {
          const response = await fetch(
            `${config?.API_URL}/employers/${employerId}/employees/${employee.id}/accounts`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${await getToken()}`,
                "Content-Type": "application/json"
              },
              body: payload
            }
          );

          if (!response.ok) {
            throw new Error("There was a problem assigning these plans");
          }

          // Update the progress on the toast
          completed += 1;
          toast.update(toastId, {
            progress: completed / totalEmployees
          });
        })
      );

      // Finish the toast
      toast.update(toastId, {
        render: "Plans assigned successfully",
        type: "success",
        autoClose: 5000,
        progress: 1
      });
    },
    onSuccess: () => {
      toast.success("Plans assigned successfully");
      employees.refetch();
      accounts.refetch();
      handleClose();
      handleComplete();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const onJobComplete = async () => {
    await employees.refetch();
    await accounts.refetch();
    handleClose();
  };

  const assignAsynchronously = useMutation({
    mutationFn: async (selectedPlans: PlanReference[]) => {
      // Initialize a progress toast
      let toastId = toast("Assigning Plans...", {
        progress: 0,
        autoClose: false,
        type: "info"
      });

      const selectedEmployeesIds = selectedEmployeesWithoutPlan.map((x) => x.id);
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/employees/accounts`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            employeeIds: selectedEmployeesIds,
            plans: selectedPlans.filter(
              (selectedPlan) => selectedPlan.tierId !== DEFAULT_VALUE
            )
          })
        }
      );

      if (!response.ok) {
        throw new Error("There was a problem assign these plans");
      }

      const responseBody = await response.json();

      checkJobProgress(
        config!.API_URL,
        responseBody.jobId,
        toastId,
        "All Plans has been assigns!",
        getToken,
        onJobComplete
      );
    },
    onSuccess: () => {
      toast.success("Plans will be assigned in background!");
      employees.refetch();
      accounts.refetch();
      handleClose();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  function handleSubmit(selectedPlans: PlanReference[]) {
    if (selectedEmployeesWithoutPlan.length === 0) {
      return;
    } else if (selectedEmployeesWithoutPlan.length <= 10) {
      assignSynchronously.mutate(selectedPlans);
    } else {
      assignAsynchronously.mutate(selectedPlans);
    }
  }

  return (
    <SelectPlans
      open={open}
      handleClose={handleClose}
      selectedEmployeesWithoutPlan={selectedEmployeesWithoutPlan.length}
      onSelectPlans={handleSubmit}
      isLoading={assignSynchronously.isLoading || assignAsynchronously.isLoading}
      error={error}
    />
  );
};

import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { ReactComponent as OnboardingStepsWireframe } from "../../assets/onboarding-steps-wireframe.svg";
import { ReactComponent as OnboardingWelcomeCard } from "../../assets/onboarding-welcome-card.svg";
import { Employer } from "../../models";

export const Step0Welcome: FC<{
  employer: Employer | undefined;
  next: () => void;
}> = ({ employer, next }) => {
  const theme = useTheme();

  return (
    <Grid container item>
      <Grid item xs={12} lg={8}>
        <Typography fontSize={28} variant="h4" my={1}>
          Welcome to HealthNow!
        </Typography>
        <Typography variant="h4" fontSize={32} fontWeight={700}>
          Now let's setup {employer?.name}'s System
        </Typography>
        <Typography mt={5} mb={3}>
          Next Steps:
        </Typography>
        <Box display="flex" gap={2}>
          <OnboardingStepsWireframe />
          <Box display="flex" flexDirection="column" gap={4.5} mt={-0.4}>
            <Box>
              <Typography fontWeight={600}>Create Employee Accounts</Typography>
              <Typography>
                Choose benefits and allowances for your staff
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={600}>Add your Team</Typography>
              <Typography>Assign Accounts to your staff</Typography>
            </Box>
            <Box>
              <Typography fontWeight={600}>Payment</Typography>
              <Typography>Add a float to activate your staff cards</Typography>
            </Box>
            <Box>
              <Typography fontWeight={600}>All done!</Typography>
              <Typography>Company setup in 5 minutes</Typography>
            </Box>
          </Box>
        </Box>
        <Button variant="contained" sx={{ my: 5 }} onClick={next}>
          Get Started
        </Button>
      </Grid>
      <Grid
        item
        sm={4}
        display={{ xs: "none", lg: "initial" }}
        sx={{ marginLeft: "auto" }}
      >
        <OnboardingWelcomeCard color={theme.palette.primary.light} />
      </Grid>
    </Grid>
  );
};
